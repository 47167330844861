import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  grid-area: leftpanel;
  background: ${(props) => props.theme.sidePanelBackground};
  position: relative;
  border-right: 1px solid ${(props) => props.theme.panelBorder};
  min-height: 400px;
`

function LeftPanel(props) {
  return <Div>{props.children}</Div>
}

LeftPanel.propTypes = {
  children: PropTypes.any,
}

export default LeftPanel
