import React, { Fragment } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Ajv from 'ajv'
import schema from 'utils/schema.json'
import media from 'utils/media-queries'
import convert from 'color-convert'
import update from 'immutability-helper'
import debounce from 'lodash.debounce'

import Layout from 'components/layout'
import TopPanel from 'components/panels/top-panel'
import LeftPanel from 'components/panels/left-panel'
import RightPanel from 'components/panels/right-panel'
import MainPanel from 'components/panels/main-panel'
import EditModeTopPanelContent from 'components/edit-mode/top-panel-content'
import LeftPanelContent from 'components/left-panel-content'
import EditModeRightPanelContent from 'components/edit-mode/right-panel-content'
import EditModeMainPanelContent from 'components/edit-mode/main-panel-content'
import MapModeTopPanelContent from 'components/map-mode/top-panel-content'
import MapModeMainPanelContent from 'components/map-mode/main-panel-content'
import Dialog from 'components/dialog/'

import GlobalStyle from 'styles/globalStyle'
import { darkTheme, lightTheme } from 'styles/theme'

import { FeedbackFish } from '@feedback-fish/react'

const App = styled.div`
  display: grid;
  grid-template-areas: ${(props) =>
    props.mode === 'edit'
      ? `
    'toppanel toppanel toppanel'
    'leftpanel main rightpanel'`
      : `
    'toppanel toppanel toppanel'
    'leftpanel main main'`};
  grid-template-columns: 240px auto 240px;
  grid-template-rows: 104px minmax(0, calc(100vh - 104px));
  height: 100vh;
  min-width: 1024px;
  min-height: 560px;
  width: 100vw;
  max-height: 100vh;
`

const MobileBlocker = styled.div`
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  display: none;
  background: ${(props) => props.theme.mobileBlockerBackground};
  color: ${(props) => props.theme.mobileBlockerText};
  ${media.sm`
    display: flex;
	`};
  align-items: center;
  justify-content: center;
  text-align: center;
`

const MobileBlockerMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Computer = styled.div`
  text-align: center;
  line-height: 160px;
  font-size: 100px;
`

const MobileBlockerText = styled.div`
  font-size: 20px;
  text-align: center;
  margin: 32px;
`

const FeedbackButton = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 10;
  text-transform: uppercase;

  box-shadow: ${(props) => props.theme.feedbackShadow};
  color: ${(props) => props.theme.buttonText};
  border: ${(props) => props.theme.buttonBorder};
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.buttonBackground};
  transition: box-shadow 300ms;
  &:hover {
    background: ${(props) => props.theme.buttonHoverBackground};
    box-shadow: ${(props) => props.theme.feedbackShadowHover};
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:active {
    background: ${(props) => props.theme.buttonActiveBackground};
    border: ${(props) => props.theme.buttonActiveBorder};
  }
  :focus {
    outline: none;
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'edit',
      currentPalettes: [],
      legacyPalettes: [],
      activePaletteIndexCurrent: null,
      activePaletteIndexLegacy: null,
      activeSwatchIndex: null,
      hueIsVisible: true,
      saturationIsVisible: true,
      valueIsVisible: true,
      inputIsFocused: false,
      dialogIsVisible: true,
      dialog: {
        type: 'splash',
        title: '',
        text: '',
        buttonText: '',
        onConfirm: '',
      },
      removePaletteMustBeConfirmed: true,
      darkTheme: true,
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', (e) => this.handleKeyboardInput(e))
    if (
      localStorage.getItem('currentPalettes') &&
      localStorage.getItem('currentPalettes') !== '[]'
    ) {
      const currentPalettes = JSON.parse(
        localStorage.getItem('currentPalettes')
      )
      this.setState({
        currentPalettes: currentPalettes,
        activePaletteIndexCurrent: 0,
      })
    }
    if (
      localStorage.getItem('legacyPalettes') &&
      localStorage.getItem('legacyPalettes') !== '[]'
    ) {
      const legacyPalettes = JSON.parse(localStorage.getItem('legacyPalettes'))
      this.setState({
        legacyPalettes: legacyPalettes,
      })
      if (localStorage.getItem('currentPalettes') === '[]') {
        this.setState({
          activePaletteIndexLegacy: 0,
        })
      }
    }
    if (localStorage.getItem('darkTheme')) {
      const darkTheme = JSON.parse(localStorage.getItem('darkTheme'))
      this.setState({
        darkTheme: darkTheme,
      })
    }
  }

  componentDidUpdate() {
    this.saveToLocalStorage()
  }

  saveToLocalStorage = debounce(() => {
    localStorage.setItem(
      'currentPalettes',
      JSON.stringify(this.state.currentPalettes)
    )
    localStorage.setItem(
      'legacyPalettes',
      JSON.stringify(this.state.legacyPalettes)
    )
  }, 100)

  handleThemeSwitch = () => {
    if (this.state.darkTheme === false) {
      this.setState({
        darkTheme: true,
      })
      localStorage.setItem('darkTheme', 'true')
    } else {
      this.setState({
        darkTheme: false,
      })
      localStorage.setItem('darkTheme', 'false')
    }
  }

  getTheme = () => {
    if (this.state.darkTheme) {
      return darkTheme
    } else {
      return lightTheme
    }
  }

  handleModeChange = (mode) => {
    if (mode === 'map' && this.state.legacyPalettes.length !== 0) {
      this.setState({
        mode: mode,
        activePaletteIndexCurrent: null,
        activePaletteIndexLegacy: 0,
      })
    } else {
      this.setState({
        mode: mode,
      })
    }
  }

  convertPalettesToHSV = (palettesHex) => {
    const currentPalettes = palettesHex
    palettesHex.forEach((palette, paletteIndex) =>
      palette.swatches.forEach((swatch, swatchIndex) => {
        const hsv = convert.hex.hsv.raw(swatch.color)
        palettesHex[paletteIndex].swatches[swatchIndex].color = hsv
      })
    )
    return currentPalettes
  }

  handleKeyboardInput = (e) => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'
    const key = e.keyCode || e.charCode

    // Backspace
    if ((key === 8 || key === 46) && this.state.inputIsFocused === false) {
      this.removeSwatch()
    }

    // Spacebar
    if (key === 32 && this.state.inputIsFocused === false) {
      this.addSwatch()
    }

    // Left key
    if (
      key === 37 &&
      this.state[activePaletteIndex] !== null &&
      this.state.activeSwatchIndex !== 0 &&
      this.state.inputIsFocused === false
    ) {
      if (this.state.activeSwatchIndex === null) {
        // If no swatch is selected start selecting a swatch beginning from the right
        const newActiveSwatchIndex =
          this.state[palettes][this.state[activePaletteIndex]].swatches.length -
          1
        this.setState({
          activeSwatchIndex: newActiveSwatchIndex,
        })
      } else {
        // If a swatch is already selected, select the swatch to the left of it
        const newActiveSwatchIndex = this.state.activeSwatchIndex - 1
        this.setState({
          activeSwatchIndex: newActiveSwatchIndex,
        })
      }
    }

    // Right key
    if (
      key === 39 &&
      this.state[activePaletteIndex] !== null &&
      this.state.activeSwatchIndex !==
        this.state[palettes][this.state[activePaletteIndex]].swatches.length -
          1 &&
      this.state.inputIsFocused === false
    ) {
      if (this.state.activeSwatchIndex === null) {
        // If no swatch is selected start selecting a swatch beginning from the left
        const newActiveSwatchIndex = 0
        this.setState({
          activeSwatchIndex: newActiveSwatchIndex,
        })
      } else {
        // If a swatch is already selected, select the swatch to the right of it
        const newActiveSwatchIndex = this.state.activeSwatchIndex + 1
        this.setState({
          activeSwatchIndex: newActiveSwatchIndex,
        })
      }
    }
  }

  // This deactivates other key events
  handleInputFocus = (inputIsFocused) => {
    this.setState({
      inputIsFocused: inputIsFocused,
    })
  }

  pushPaletteToLegacy = (paletteIndex) => {
    const palette = this.state.currentPalettes[paletteIndex]
    const newPalettes = update(this.state.currentPalettes, {
      $splice: [[[paletteIndex], 1]],
    })
    const newLegacyPalettes = update(this.state.legacyPalettes, {
      $push: [palette],
    })
    this.setState({
      currentPalettes: newPalettes,
      legacyPalettes: newLegacyPalettes,
      activePaletteIndexCurrent: null,
      activePaletteIndexLegacy: this.state.legacyPalettes.length,
      activeSwatchIndex: null,
    })
  }

  pushPaletteToCurrent = (paletteIndex) => {
    const palette = this.state.legacyPalettes[paletteIndex]
    const newPalettes = update(this.state.legacyPalettes, {
      $splice: [[[paletteIndex], 1]],
    })
    const newCurrentPalettes = update(this.state.currentPalettes, {
      $push: [palette],
    })
    this.setState({
      legacyPalettes: newPalettes,
      currentPalettes: newCurrentPalettes,
      activePaletteIndexCurrent: this.state.currentPalettes.length,
      activePaletteIndexLegacy: null,
      activeSwatchIndex: null,
    })
  }

  showImportPalettesDialog = () => {
    this.createDialog(
      'import',
      'Import',
      'Import your palettes as JSON object.',
      'Import',
      this.importPalettes
    )
  }

  importPalettes = (userInput) => {
    if (userInput) {
      const doc = new DOMParser().parseFromString(userInput, 'text/html')
      const strippedUserInput = doc.body.textContent || ''
      try {
        const json = JSON.parse(strippedUserInput)
        const jsonIsValid = this.valJson(json)
        if (jsonIsValid) {
          const newPalettesHex = json
          const newcurrentPalettes = this.convertPalettesToHSV(newPalettesHex)
          newcurrentPalettes.forEach((palette) => {
            this.setState(this.addPalette(palette))
          })
          this.closeDialog()
        } else {
          alert(
            'Please check the format of your input. Click on cancel and on the import button again to view an example.'
          )
        }
      } catch (e) {
        alert('Please check the format of your input. --- ' + e)
      }
    }
  }

  valJson = (json) => {
    const ajv = new Ajv()
    const valid = ajv.validate(schema, json)
    return valid
  }

  showExportPalettesDialog = () => {
    this.createDialog(
      'export',
      'Export',
      'Export your palettes as JSON object.',
      'Export'
    )
  }

  setActivePalette = (activePaletteIndex, paletteType) => {
    if (paletteType === 'current') {
      this.setState({
        activePaletteIndexCurrent: activePaletteIndex,
        activePaletteIndexLegacy: null,
        activeSwatchIndex: null,
      })
    } else if (paletteType === 'legacy') {
      this.setState({
        activePaletteIndexCurrent: null,
        activePaletteIndexLegacy: activePaletteIndex,
        activeSwatchIndex: null,
      })
    }
    this.handleInputFocus(false)
  }

  setActiveSwatch = (newActiveSwatchIndex, nodeHasBeenClicked) => {
    if (
      // If the swatch is already selected, deselect it instead
      newActiveSwatchIndex === this.state.activeSwatchIndex &&
      nodeHasBeenClicked !== true // But only if the user did not try to drag a node on this swatch
    ) {
      this.handleInputFocus(false)
      this.setState({
        activeSwatchIndex: null,
      })
    } else {
      this.setState({
        activeSwatchIndex: newActiveSwatchIndex,
      })
    }
  }

  addSwatch = () => {
    let palettes
    let activePaletteIndex
    if (this.state.activePaletteIndexLegacy === null) {
      palettes = 'currentPalettes'
      activePaletteIndex = 'activePaletteIndexCurrent'
    } else {
      palettes = 'legacyPalettes'
      activePaletteIndex = 'activePaletteIndexLegacy'
    }

    if (this.state[activePaletteIndex] !== null) {
      if (this.state.activeSwatchIndex === null) {
        // If no swatch is selected, add the new swatch at the end of the palette
        const paletteLength = this.state[palettes][
          this.state[activePaletteIndex]
        ].swatches.length
        let newColor
        if (paletteLength === 0) {
          newColor = [180, 0, 100]
        } else {
          newColor = this.state[palettes][this.state[activePaletteIndex]]
            .swatches[paletteLength - 1].color
        }
        const newSwatch = {
          name: 'New Swatch',
          color: newColor,
        }
        const newPalettes = update(this.state[palettes], {
          [this.state[activePaletteIndex]]: {
            swatches: {
              $push: [newSwatch],
            },
          },
        })
        this.setState({
          [palettes]: newPalettes,
          activeSwatchIndex: paletteLength,
        })
      } else {
        // If a swatch is selected, add the new swatch right to the selected one
        const newcolor = this.state[palettes][this.state[activePaletteIndex]]
          .swatches[this.state.activeSwatchIndex].color
        const newSwatch = {
          name: 'New Swatch',
          color: newcolor,
        }
        const newActiveSwatchIndex = this.state.activeSwatchIndex + 1
        const newPalettes = update(this.state[palettes], {
          [this.state[activePaletteIndex]]: {
            swatches: {
              $splice: [[newActiveSwatchIndex, 0, newSwatch]],
            },
          },
        })
        this.setState({
          [palettes]: newPalettes,
          activeSwatchIndex: newActiveSwatchIndex,
        })
      }
    }
  }

  removeSwatch = () => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    // Check if palette is already empty
    if (
      this.state.activeSwatchIndex === null ||
      this.state[palettes][this.state[activePaletteIndex]].swatches.length === 0
    ) {
      return
    }
    let lastSwatchGetsDeleted = false
    const oldActiveSwatchIndex = this.state.activeSwatchIndex
    let newActiveSwatchIndex
    if (
      oldActiveSwatchIndex ===
      this.state[palettes][this.state[activePaletteIndex]].swatches.length - 1
    ) {
      // If it is most right swatch in the palette, select the swatch left to it after it was deleted
      if (oldActiveSwatchIndex !== 0) {
        newActiveSwatchIndex = oldActiveSwatchIndex - 1
      } else {
        // Remember if it was the last remaining swatch in the palette,
        newActiveSwatchIndex = 0
        lastSwatchGetsDeleted = true
      }
    } else {
      // If it was not the most right swatch in the palette, after deletion the swatch that slides into the same place will be selected
      newActiveSwatchIndex = oldActiveSwatchIndex
    }
    const newPalettes = update(this.state[palettes], {
      [this.state[activePaletteIndex]]: {
        swatches: {
          $splice: [[this.state.activeSwatchIndex, 1]],
        },
      },
    })

    if (lastSwatchGetsDeleted === true) {
      this.setState({
        [palettes]: newPalettes,
        activeSwatchIndex: null,
      })
    } else {
      this.setState({
        [palettes]: newPalettes,
        activeSwatchIndex: newActiveSwatchIndex,
      })
    }
  }

  onAddPalette = () => {
    this.setState(this.addPalette())
  }

  addPalette = (newPalette) => {
    return (state) => {
      const palettesLength = state.currentPalettes.length
      if (newPalette === undefined) {
        const newHue = Math.floor(Math.random() * 360) + 0
        newPalette = {
          paletteName: 'New Palette',
          swatches: [
            { name: 'New Swatch', color: [newHue, 90, 20] },
            { name: 'New Swatch', color: [newHue, 80, 40] },
            { name: 'New Swatch', color: [newHue, 70, 60] },
            { name: 'New Swatch', color: [newHue, 60, 80] },
            { name: 'New Swatch', color: [newHue, 50, 100] },
          ],
        }
      }
      return {
        currentPalettes: update(state.currentPalettes, {
          $push: [newPalette],
        }),
        activePaletteIndexCurrent: palettesLength,
        activePaletteIndexLegacy: null,
        activeSwatchIndex: null,
      }
    }
  }

  handleRemovePaletteMustBeConfirmedChange = (boolean) => {
    this.setState({
      removePaletteMustBeConfirmed: boolean,
    })
  }

  confirmRemovePalette = () => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    if (
      this.state[activePaletteIndex] === null ||
      this.state[palettes].length === 0
    ) {
      return
    }
    this.createDialog(
      'confirm',
      'Delete palette?',
      'Do you really want to delete this palette and all its swatches?',
      'Delete',
      this.removePalette
    )
  }

  removePalette = () => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    //works in the same way as removeSwatch()
    if (
      this.state[activePaletteIndex] === null ||
      this.state[palettes].length === 0
    ) {
      return
    }
    let lastPaletteGetsDeleted = false
    const oldActivePaletteIndex = this.state[activePaletteIndex]
    let newActivePaletteIndex
    if (oldActivePaletteIndex === this.state[palettes].length - 1) {
      if (oldActivePaletteIndex !== 0) {
        newActivePaletteIndex = oldActivePaletteIndex - 1
      } else {
        newActivePaletteIndex = 0
        lastPaletteGetsDeleted = true
      }
    } else {
      newActivePaletteIndex = oldActivePaletteIndex
    }
    const newPalettes = update(this.state[palettes], {
      $splice: [[this.state[activePaletteIndex], 1]],
    })
    if (lastPaletteGetsDeleted === true) {
      this.setState({
        [palettes]: newPalettes,
        [activePaletteIndex]: null,
        activeSwatchIndex: null,
      })
    } else {
      this.setState({
        [palettes]: newPalettes,
        [activePaletteIndex]: newActivePaletteIndex,
        activeSwatchIndex: null,
      })
    }
  }

  changePaletteName = (newName) => {
    if (this.state.activePaletteIndexCurrent === null) {
      const newPalettes = update(this.state.legacyPalettes, {
        [this.state.activePaletteIndexLegacy]: {
          paletteName: { $set: newName },
        },
      })
      this.setState({
        legacyPalettes: newPalettes,
      })
    } else {
      const newcurrentPalettes = update(this.state.currentPalettes, {
        [this.state.activePaletteIndexCurrent]: {
          paletteName: { $set: newName },
        },
      })
      this.setState({
        currentPalettes: newcurrentPalettes,
      })
    }
  }

  changeName = (newName) => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    const newPalettes = update(this.state[palettes], {
      [this.state[activePaletteIndex]]: {
        swatches: {
          [this.state.activeSwatchIndex]: {
            name: { $set: newName },
          },
        },
      },
    })
    this.setState({
      [palettes]: newPalettes,
    })
  }

  changeHSV = (value, colorChannel) => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    if (typeof value === 'number') {
      const newPalettes = update(this.state[palettes], {
        [this.state[activePaletteIndex]]: {
          swatches: {
            [this.state.activeSwatchIndex]: {
              color: { [colorChannel]: { $set: value } },
            },
          },
        },
      })
      this.setState({
        [palettes]: newPalettes,
      })
    } else if (typeof value === 'object') {
      const values = value
      let newPalettes = [...this.state[palettes]]
      for (let colorChannel = 0; colorChannel < 3; colorChannel++) {
        values[colorChannel].forEach((value, index) => {
          newPalettes = update(newPalettes, {
            [this.state[activePaletteIndex]]: {
              swatches: {
                [index]: {
                  color: { [colorChannel]: { $set: value } },
                },
              },
            },
          })
        })
      }
      this.setState({
        [palettes]: newPalettes,
      })
    }
  }

  changeHex = (newHex) => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'

    const newHSV = convert.hex.hsv.raw(newHex)
    const newPalettes = update(this.state[palettes], {
      [this.state[activePaletteIndex]]: {
        swatches: {
          [this.state.activeSwatchIndex]: {
            color: { $set: newHSV },
          },
        },
      },
    })
    this.setState({
      [palettes]: newPalettes,
    })
  }

  changeRGB = (newRGBValue, colorChannel) => {
    const activePaletteIndex =
      this.state.activePaletteIndexCurrent === null
        ? 'activePaletteIndexLegacy'
        : 'activePaletteIndexCurrent'
    const palettes =
      this.state.activePaletteIndexCurrent === null
        ? 'legacyPalettes'
        : 'currentPalettes'
    const oldActiveSwatchColor = this.state[palettes][
      this.state[activePaletteIndex]
    ].swatches[this.state.activeSwatchIndex].color
    const newRGB = []

    newRGB[0] = convert.hsv.rgb(oldActiveSwatchColor)[0]
    newRGB[1] = convert.hsv.rgb(oldActiveSwatchColor)[1]
    newRGB[2] = convert.hsv.rgb(oldActiveSwatchColor)[2]
    newRGB[colorChannel] = newRGBValue

    const newHSV = convert.rgb.hsv.raw(newRGB)

    const newPalettes = update(this.state[palettes], {
      [this.state[activePaletteIndex]]: {
        swatches: {
          [this.state.activeSwatchIndex]: {
            color: { $set: newHSV },
          },
        },
      },
    })
    this.setState({
      [palettes]: newPalettes,
    })
  }

  toggleHue = () => {
    const newHueIsVisible = !this.state.hueIsVisible
    this.setState({ hueIsVisible: newHueIsVisible })
  }

  toggleSaturation = () => {
    const newSaturationIsVisible = !this.state.saturationIsVisible
    this.setState({ saturationIsVisible: newSaturationIsVisible })
  }

  toggleValue = () => {
    const newValueIsVisible = !this.state.valueIsVisible
    this.setState({ valueIsVisible: newValueIsVisible })
  }

  straighten = () => {
    this.handleStraightenClickInMain()
  }

  createDialog = (type, title, text, buttonText, onConfirm) => {
    const newDialog = {
      type: type,
      title: title,
      text: text,
      buttonText: buttonText,
      onConfirm: onConfirm,
    }
    this.setState({
      dialogIsVisible: true,
      dialog: newDialog,
    })
  }

  closeDialog = () => {
    this.setState({ dialogIsVisible: false, inputIsFocused: false })
  }

  getActivePalette = () => {
    if (this.state.activePaletteIndexCurrent === null) {
      if (this.state.activePaletteIndexLegacy === null) {
        return null
      }
      return this.state.legacyPalettes[this.state.activePaletteIndexLegacy]
    }
    return this.state.currentPalettes[this.state.activePaletteIndexCurrent]
  }

  getActivePaletteIndex = () => {
    if (this.state.activePaletteIndexCurrent === null) {
      if (this.state.activePaletteIndexLegacy === null) {
        return null
      }
      return this.state.activePaletteIndexLegacy
    }
    return this.state.activePaletteIndexCurrent
  }

  render() {
    const editApp = (
      <Fragment>
        <TopPanel>
          <EditModeTopPanelContent
            addSwatch={this.addSwatch}
            toggleHue={this.toggleHue}
            toggleSaturation={this.toggleSaturation}
            toggleValue={this.toggleValue}
            straighten={this.straighten}
            importPalettes={this.showImportPalettesDialog}
            exportPalettes={this.showExportPalettesDialog}
            hueIsVisible={this.state.hueIsVisible}
            saturationIsVisible={this.state.saturationIsVisible}
            valueIsVisible={this.state.valueIsVisible}
            handleModeChange={this.handleModeChange}
            darkTheme={this.state.darkTheme}
          />
        </TopPanel>
        <LeftPanel>
          <LeftPanelContent
            heading="Palettes"
            changePaletteName={this.changePaletteName}
            handleInputFocus={this.handleInputFocus}
            addPalette={() => this.onAddPalette(this.state.mode)}
            removePalette={
              this.state.removePaletteMustBeConfirmed
                ? this.confirmRemovePalette
                : this.removePalette
            }
            setActivePalette={this.setActivePalette}
            activePaletteIndexCurrent={this.state.activePaletteIndexCurrent}
            activePaletteIndexLegacy={this.state.activePaletteIndexLegacy}
            palettes={this.state.currentPalettes}
            legacyPalettes={this.state.legacyPalettes}
            mode={this.state.mode}
            pushPaletteToLegacy={this.pushPaletteToLegacy}
            pushPaletteToCurrent={this.pushPaletteToCurrent}
            handleThemeSwitch={this.handleThemeSwitch}
            darkTheme={this.state.darkTheme}
          />
        </LeftPanel>
        <MainPanel>
          <EditModeMainPanelContent
            palettesCount={
              this.state.currentPalettes.length +
              this.state.legacyPalettes.length
            }
            activePaletteIndexLegacy={this.state.activePaletteIndexLegacy}
            activePalette={this.getActivePalette()}
            setActiveSwatch={this.setActiveSwatch}
            activeSwatchIndex={this.state.activeSwatchIndex}
            changeHSV={this.changeHSV}
            activePaletteIndex={this.state.activePaletteIndexCurrent}
            hueIsVisible={this.state.hueIsVisible}
            saturationIsVisible={this.state.saturationIsVisible}
            valueIsVisible={this.state.valueIsVisible}
            straighten={(straightenClickHandler) =>
              (this.handleStraightenClickInMain = straightenClickHandler)
            }
            createDialog={this.createDialog}
            darkTheme={this.state.darkTheme}
          />
        </MainPanel>
        <RightPanel>
          <EditModeRightPanelContent
            activeSwatchName={
              this.state.activeSwatchIndex !== null &&
              this.getActivePalette().swatches[this.state.activeSwatchIndex] !==
                undefined &&
              this.getActivePalette().swatches[this.state.activeSwatchIndex]
                .name
            }
            activeSwatchColor={
              this.state.activeSwatchIndex !== null &&
              this.getActivePalette().swatches[this.state.activeSwatchIndex] !==
                undefined &&
              this.getActivePalette().swatches[this.state.activeSwatchIndex]
                .color
            }
            handleInputFocus={this.handleInputFocus}
            changeName={this.changeName}
            changeHSV={this.changeHSV}
            changeHex={this.changeHex}
            changeRGB={this.changeRGB}
            removeSwatch={this.removeSwatch}
          />
        </RightPanel>

        {this.state.dialogIsVisible && (
          <Dialog
            closeDialog={this.closeDialog}
            dialog={this.state.dialog}
            handleInputFocus={this.handleInputFocus}
            palettes={this.state.currentPalettes}
            handleRemovePaletteMustBeConfirmedChange={
              this.handleRemovePaletteMustBeConfirmedChange
            }
            darkTheme={this.state.darkTheme}
          />
        )}
      </Fragment>
    )

    const mapApp = (
      <Fragment>
        <TopPanel>
          <MapModeTopPanelContent handleModeChange={this.handleModeChange} />
        </TopPanel>
        <LeftPanel>
          <LeftPanelContent
            heading="Palettes"
            changePaletteName={this.changePaletteName}
            handleInputFocus={this.handleInputFocus}
            addPalette={() => this.onAddPalette(this.state.mode)}
            removePalette={
              this.state.removePaletteMustBeConfirmed
                ? this.confirmRemovePalette
                : this.removePalette
            }
            setActivePalette={this.setActivePalette}
            activePaletteIndexCurrent={this.state.activePaletteIndexCurrent}
            activePaletteIndexLegacy={this.state.activePaletteIndexLegacy}
            palettes={this.state.currentPalettes}
            legacyPalettes={this.state.legacyPalettes}
            mode={this.state.mode}
            pushPaletteToLegacy={this.pushPaletteToLegacy}
            pushPaletteToCurrent={this.pushPaletteToCurrent}
            handleThemeSwitch={this.handleThemeSwitch}
            darkTheme={this.state.darkTheme}
          />
        </LeftPanel>
        <MainPanel>
          <MapModeMainPanelContent
            activePalette={
              this.state.legacyPalettes[this.state.activePaletteIndexLegacy]
            }
            currentPalettes={this.state.currentPalettes}
            paletteCount={this.state.legacyPalettes.length}
            darkTheme={this.state.darkTheme}
          />
        </MainPanel>
        {this.state.dialogIsVisible && (
          <Dialog
            closeDialog={this.closeDialog}
            dialog={this.state.dialog}
            handleInputFocus={this.handleInputFocus}
            palettes={this.state.legacyPalettes}
            handleRemovePaletteMustBeConfirmedChange={
              this.handleRemovePaletteMustBeConfirmedChange
            }
          />
        )}
      </Fragment>
    )

    return (
      <ThemeProvider theme={this.getTheme()}>
        <Layout>
          <GlobalStyle darkTheme={this.state.darkTheme} />
          <FeedbackFish projectId="fd2e4f2a3de46a">
            <FeedbackButton>?</FeedbackButton>
          </FeedbackFish>
          <MobileBlocker>
            <MobileBlockerMessageWrapper>
              <Computer>
                <span role="img" aria-label="Laptop">
                  💻
                </span>
              </Computer>
              <MobileBlockerText>
                This app only works on desktop for now.
              </MobileBlockerText>
            </MobileBlockerMessageWrapper>
          </MobileBlocker>
          <App mode={this.state.mode}>
            {this.state.mode === 'edit' && editApp}
            {this.state.mode === 'map' && mapApp}
          </App>
        </Layout>
      </ThemeProvider>
    )
  }
}

export default IndexPage
