import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convert from 'color-convert'
import { hex as getContrast } from 'wcag-contrast'

import Heading from 'components/heading'
import { Button } from 'components/button'

const PanelContainer = styled.div`
  padding: 0 16px;
`

const SwatchValues = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const SwatchValue = styled.input`
  box-sizing: border-box;
  color: ${(props) => props.theme.rightPanelInputText};
  min-width: 0;
  width: 0;
  flex-basis: 33.333333%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-right: 4px;
  background: ${(props) => props.theme.rightPanelInputBackground};
  flex-grow: 1;
  border: ${(props) => props.theme.rightPanelInputBorder};
  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  &:last-of-type {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
  }
  &:only-child {
    border-radius: 4px;
  }
  &:focus {
    border: 1px solid transparent;
  }
`

const PanelEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const Contrast = styled.table`
  table-layout: fixed;
  width: 208px;
`

const CenterTd = styled.td`
  text-align: center;
`

const ContrastInfo = styled.div`
  color: ${(props) => props.theme.rightPanelContrastInfoText};
  background: ${(props) => props.theme.rightPanelContrastInfoBackground};
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: inline-block;
  margin-left: 110px;
  font-size: 13px;
`

const ContrastInfoLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${(props) => props.theme.rightPanelContrastInfoText};
  &:visited {
    color: ${(props) => props.theme.rightPanelContrastInfoText};
  }
  &:active {
    color: ${(props) => props.theme.rightPanelContrastInfoText};
  }
  &:hover {
    color: ${(props) => props.theme.rightPanelContrastInfoText};
  }
`

const Label = styled.div`
  width: 56px;
`

const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.panelBorder};
  margin-top: 24px;
  height: 24px;
`

class RightPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameOverride: false,
      hueOverride: false,
      saturationOverride: false,
      valueOverride: false,
      localNameValue: '',
      localHueValue: 0,
      localSaturationValue: 0,
      localValueValue: 0,
      hexOverride: false,
      localHexValue: '000000',
      redOverride: false,
      greenOverride: false,
      blueOverride: false,
      localRedValue: 0,
      localGreenValue: 0,
      localBlueValue: 0,
    }
  }

  getNameValue = () => {
    if (this.state.nameOverride === true) {
      return this.state.localNameValue
    }
    return this.props.activeSwatchName
  }

  setNameValue = (value) => {
    let isValidName = true
    if (!value.replace(/\s/g, '').length) {
      isValidName = false
    }
    if (isValidName === true) {
      this.props.changeName(value)
      this.setState({ nameOverride: false })
    } else {
      this.setState({ nameOverride: true })
    }
    this.setState({
      localNameValue: value,
    })
  }

  resetNameValue = (value) => {
    this.setState({ nameOverride: false })
    this.props.changeName(value.trim())
    this.props.handleInputFocus(false)
  }

  getHSVValue = (colorChannel) => {
    if (this.state.hueOverride === true && colorChannel === 0) {
      return this.state.localHueValue
    }
    if (this.state.saturationOverride === true && colorChannel === 1) {
      return this.state.localSaturationValue
    }
    if (this.state.valueOverride === true && colorChannel === 2) {
      return this.state.localValueValue
    }
    return this.props.activeSwatchColor[colorChannel].toFixed(0)
  }

  setHSVValue = (value, colorChannel) => {
    const isValidHSV = /^\d+$/.test(value)
    if (isValidHSV === true) {
      const valueInt = Number(value)
      if (colorChannel === 0) {
        if (valueInt < 0) {
          this.props.changeHSV(0, colorChannel)
        } else if (valueInt > 360) {
          this.props.changeHSV(360, colorChannel)
        } else {
          this.props.changeHSV(valueInt, colorChannel)
        }
      } else {
        if (valueInt < 0) {
          this.props.changeHSV(0, colorChannel)
        } else if (valueInt > 100) {
          this.props.changeHSV(100, colorChannel)
        } else {
          this.props.changeHSV(valueInt, colorChannel)
        }
      }
      this.setState({
        hueOverride: false,
        saturationOverride: false,
        valueOverride: false,
      })
    } else {
      if (colorChannel === 0) {
        this.setState({ hueOverride: true, localHueValue: value })
      }
      if (colorChannel === 1) {
        this.setState({ saturationOverride: true, localSaturationValue: value })
      }
      if (colorChannel === 2) {
        this.setState({ valueOverride: true, localValueValue: value })
      }
    }
  }

  resetHSVValue = () => {
    const hue = this.props.activeSwatchColor[0]
    const saturation = this.props.activeSwatchColor[1]
    const value = this.props.activeSwatchColor[2]
    this.setState({
      hueOverride: false,
      saturationOverride: false,
      valueOverride: false,
      localHueValue: hue,
      localSaturationValue: saturation,
      localValueValue: value,
    })
    this.props.handleInputFocus(false)
  }

  getHexValue = () => {
    if (this.state.hexOverride === true) {
      return this.state.localHexValue
    }
    return '#' + convert.hsv.hex(this.props.activeSwatchColor)
  }

  setHexValue = (value) => {
    if (value.substring(0, 1) !== '#') {
      value = '#' + value
    }
    const isValidHex = /^#[0-9A-F]{6}$/i.test(value)
    if (isValidHex === true) {
      this.props.changeHex(value.substring(1, 7))
      this.setState({ hexOverride: false })
    } else {
      this.setState({ hexOverride: true })
    }
    this.setState({
      localHexValue: value,
    })
  }

  resetHexValue = () => {
    this.setState({ hexOverride: false })
    this.props.handleInputFocus(false)
  }

  getRGBValue = (colorChannel) => {
    if (this.state.redOverride === true && colorChannel === 0) {
      return this.state.localRedValue
    }
    if (this.state.greenOverride === true && colorChannel === 1) {
      return this.state.localGreenValue
    }
    if (this.state.blueOverride === true && colorChannel === 2) {
      return this.state.localBlueValue
    }
    return convert.hsv
      .rgb(this.props.activeSwatchColor)
      [colorChannel].toFixed(0)
  }

  setRGBValue = (value, colorChannel) => {
    const isValidRGB = /^\d+$/.test(value)
    if (isValidRGB === true) {
      const valueNum = Number(value)
      if (valueNum < 0) {
        this.props.changeRGB(0, colorChannel)
      } else if (valueNum > 255) {
        this.props.changeRGB(255, colorChannel)
      } else {
        this.props.changeRGB(valueNum, colorChannel)
      }
      this.setState({
        redOverride: false,
        greenOverride: false,
        blueOverride: false,
      })
    } else {
      if (colorChannel === 0) {
        this.setState({ redOverride: true, localRedValue: value })
      }
      if (colorChannel === 1) {
        this.setState({ greenOverride: true, localGreenValue: value })
      }
      if (colorChannel === 2) {
        this.setState({ blueOverride: true, localBlueValue: value })
      }
    }
  }

  resetRGBValue = () => {
    const Red = convert.hsv.rgb(this.props.activeSwatchColor)[0]
    const Green = convert.hsv.rgb(this.props.activeSwatchColor)[1]
    const Blue = convert.hsv.rgb(this.props.activeSwatchColor)[2]
    this.setState({
      redOverride: false,
      greenOverride: false,
      blueOverride: false,
      localRedValue: Red,
      localGreenValue: Green,
      localBlueValue: Blue,
    })
    this.props.handleInputFocus(false)
  }

  handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.refs.swatchValue0.blur()
      this.refs.swatchValue1.blur()
      this.refs.swatchValue2.blur()
      this.refs.swatchValue3.blur()
      this.refs.swatchValue4.blur()
      this.refs.swatchValue5.blur()
      this.refs.swatchValue6.blur()
      this.refs.swatchValue7.blur()
      this.props.handleInputFocus(false)
    }
  }

  isContrastAccessible = (ratio, color) => {
    if (
      getContrast(convert.hsv.hex(this.props.activeSwatchColor), color) > ratio
    ) {
      return '✓'
    } else {
      return ''
    }
  }

  render() {
    const name = this.props.activeSwatchColor && (
      <PanelEntry>
        <Label>Name</Label>
        <SwatchValues>
          <SwatchValue
            ref="swatchValue0"
            type="text"
            value={this.getNameValue()}
            onChange={(e) => this.setNameValue(e.target.value)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={(e) => this.resetNameValue(e.target.value)}
            onKeyPress={(e) => this.handleEnterPress(e)}
            autoComplete="off"
            spellCheck="false"
          />
        </SwatchValues>
      </PanelEntry>
    )

    const hsv = this.props.activeSwatchColor && (
      <PanelEntry>
        <Label>HSV</Label>
        <SwatchValues>
          <SwatchValue
            ref="swatchValue1"
            type="number"
            value={this.getHSVValue(0)}
            onChange={(e) => this.setHSVValue(e.target.value, 0)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetHSVValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
          <SwatchValue
            ref="swatchValue2"
            type="number"
            value={this.getHSVValue(1)}
            onChange={(e) => this.setHSVValue(e.target.value, 1)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetHSVValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
          <SwatchValue
            ref="swatchValue3"
            type="number"
            value={this.getHSVValue(2)}
            onChange={(e) => this.setHSVValue(e.target.value, 2)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetHSVValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
        </SwatchValues>
      </PanelEntry>
    )

    const hex = this.props.activeSwatchColor && (
      <PanelEntry>
        <Label>Hex</Label>
        <SwatchValues>
          <SwatchValue
            ref="swatchValue4"
            type="text"
            value={this.getHexValue()}
            onChange={(e) => this.setHexValue(e.target.value)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetHexValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
            autoComplete="off"
            spellCheck="false"
          />
        </SwatchValues>
      </PanelEntry>
    )

    const rgb = this.props.activeSwatchColor && (
      <PanelEntry>
        <Label>RGB</Label>
        <SwatchValues>
          <SwatchValue
            ref="swatchValue5"
            type="number"
            value={this.getRGBValue(0)}
            onChange={(e) => this.setRGBValue(e.target.value, 0)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetRGBValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
          <SwatchValue
            ref="swatchValue6"
            type="number"
            value={this.getRGBValue(1)}
            onChange={(e) => this.setRGBValue(e.target.value, 1)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetRGBValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
          <SwatchValue
            ref="swatchValue7"
            type="number"
            value={this.getRGBValue(2)}
            onChange={(e) => this.setRGBValue(e.target.value, 2)}
            onFocus={() => this.props.handleInputFocus(true)}
            onBlur={this.resetRGBValue}
            onKeyPress={(e) => this.handleEnterPress(e)}
          />
        </SwatchValues>
      </PanelEntry>
    )

    const contrast = this.props.activeSwatchColor && (
      <Contrast>
        <tbody>
          <tr>
            <td> </td>
            <CenterTd>White</CenterTd>
            <CenterTd>Black</CenterTd>
          </tr>
          <tr>
            <td>AA</td>
            <CenterTd>{this.isContrastAccessible(4.5, '#fff')}</CenterTd>
            <CenterTd>{this.isContrastAccessible(4.5, '#000')}</CenterTd>
          </tr>
          <tr>
            <td>AAA</td>
            <CenterTd>{this.isContrastAccessible(7, '#fff')}</CenterTd>
            <CenterTd>{this.isContrastAccessible(7, '#000')}</CenterTd>
          </tr>
          <tr>
            <td>Lg AA</td>
            <CenterTd>{this.isContrastAccessible(3, '#fff')}</CenterTd>
            <CenterTd>{this.isContrastAccessible(3, '#000')}</CenterTd>
          </tr>
          <tr>
            <td>Lg AAA</td>
            <CenterTd>{this.isContrastAccessible(4.5, '#fff')}</CenterTd>
            <CenterTd>{this.isContrastAccessible(4.5, '#000')}</CenterTd>
          </tr>
        </tbody>
      </Contrast>
    )
    const removeSwatchButton = this.props.activeSwatchColor && (
      <Button onClick={this.props.removeSwatch}>Delete Swatch</Button>
    )

    return (
      <Fragment>
        {this.props.activeSwatchColor && <Heading>Swatch</Heading>}
        <PanelContainer>
          {name}
          {hsv}
          {hex}
          {rgb}
        </PanelContainer>
        {this.props.activeSwatchColor && (
          <Heading>
            Contrast
            <ContrastInfo>
              <ContrastInfoLink
                href="https://webaim.org/articles/contrast/"
                target="blank"
              >
                ?
              </ContrastInfoLink>
            </ContrastInfo>
          </Heading>
        )}
        <PanelContainer>{contrast}</PanelContainer>
        {this.props.activeSwatchColor && <Separator />}
        <PanelContainer>{removeSwatchButton}</PanelContainer>
      </Fragment>
    )
  }
}

RightPanelContent.propTypes = {
  activeSwatchColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  activeSwatchName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  changeHSV: PropTypes.func,
  changeHex: PropTypes.func,
  changeName: PropTypes.func,
  changeRGB: PropTypes.func,
  handleInputFocus: PropTypes.func,
  removeSwatch: PropTypes.func,
}

export default RightPanelContent
