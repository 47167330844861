import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  grid-area: toppanel;
  background: ${(props) => props.theme.topPanelBackground};
  display: flex;
  padding: 24px 16px 16px 16px;
  z-index: 1;
  border-bottom: 1px solid ${(props) => props.theme.topPanelBorder};
`

function TopPanel(props) {
  return <Div>{props.children}</Div>
}

TopPanel.propTypes = {
  children: PropTypes.any,
}

export default TopPanel
