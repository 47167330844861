import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  grid-area: rightpanel;
  background: ${(props) => props.theme.sidePanelBackground};
  max-height: calc(100vh - 96px);
  overflow-y: auto;
  border-left: 1px solid ${(props) => props.theme.panelBorder};
`

function RightPanel(props) {
  return <Div>{props.children}</Div>
}

RightPanel.propTypes = {
  children: PropTypes.any,
}

export default RightPanel
