const importPlaceholder = `<pre>
[{
  "paletteName": "Example",
  "swatches": [
    { "name": "dark green", "color": "0E7500" },
    { "name": "medium green", "color": "5DD24D" },
    { "name": "light green", "color": "CCFFC5" }
  ]
}]</pre>`

export default importPlaceholder
