import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

import DialogFrame from './dialog-frame'

const Div = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.sm`
    display: none;
	`};
`

const Passepartout = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(
    0,
    0,
    0,
    ${(props) => props.theme.dialogPassepartoutOpacity}
  );
  position: absolute;
  top: 0;
  left: 0;
`

class Dialog extends React.Component {
  render() {
    return (
      <Div>
        <Passepartout onClick={this.props.closeDialog} />
        <DialogFrame
          closeDialog={this.props.closeDialog}
          dialog={this.props.dialog}
          handleInputFocus={this.props.handleInputFocus}
          palettes={this.props.palettes}
          handleRemovePaletteMustBeConfirmedChange={
            this.props.handleRemovePaletteMustBeConfirmedChange
          }
          darkTheme={this.props.darkTheme}
        />
      </Div>
    )
  }
}

Dialog.propTypes = {
  closeDialog: PropTypes.func,
  dialog: PropTypes.object,
  handleInputFocus: PropTypes.func,
  palettes: PropTypes.array,
  handleRemovePaletteMustBeConfirmedChange: PropTypes.func,
  darkTheme: PropTypes.bool,
}

export default Dialog
