import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  grid-area: main;
  background: ${(props) => props.theme.mainPanelBackground};
  position: relative;
  min-height: 400px;
`

function MainPanel(props) {
  return <Div>{props.children}</Div>
}

MainPanel.propTypes = {
  children: PropTypes.any,
}

export default MainPanel
