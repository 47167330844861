import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Heading from 'components/heading'
import { Button } from 'components/button'

const AddRemoveButton = styled.div`
  color: ${(props) => props.theme.leftPanelAddRemoveButtonText};
  display: inline-block;
  flex-grow: 0;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 6px;
  font-size: 23px;
  line-height: 0.4;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
`

const AddRemoveButtonContainer = styled.div``

const ScrollContainer = styled.div`
  flex-direction: column;
  width: 100%;
  top: 56px;
  overflow-y: auto;
  max-height: calc(100% - 56px);
  padding-bottom: 96px;
`

const PalettePanelList = styled.div`
  flex-direction: column;
  width: 100%;
  padding: 0 16px 0 16px;
  top: 56px;
  overflow-y: auto;
  max-height: calc(100% - 56px);
`

const PalettePanelEntryWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
`

const PalettePanelEntry = styled.input`
  color: ${(props) => props.theme.leftPanelPaletteText};
  cursor: ${(props) => (props.paletteNameIsBeingEdited ? 'auto' : 'default')};
  width: 100%;
  height: 40px;
  border: none;
  max-height: 40px;
  overflow: hidden;
  align-items: center;
  padding: 0 8px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 4px;
  user-select: ${(props) => (props.paletteNameIsBeingEdited ? 'auto' : 'none')};
  background: ${(props) =>
    props.paletteIsSelected
      ? props.paletteNameIsBeingEdited
        ? props.theme.leftPanelPaletteNameBeingEditedBackground
        : props.theme.leftPanelPaletteSelectedBackground
      : 'transparent'};
`

const SwitchLegacyButton = styled.div`
  min-width: 16px;
  min-height: 40px;
  border-radius: 4px;
  color: ${(props) => props.theme.leftPanelSwitchLegacyButtonText};
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  user-select: none;
`

const ThemeSwitcherWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 72px;
  background: ${(props) => props.theme.leftPanelThemeSwitcherBackground};
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${(props) => props.theme.panelBorder};
`

class LeftPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paletteNameIsBeingEdited: false,
      paletteIndexBeingEdited: null,
      paletteTypeBeingEdited: null,
      paletteRefBeingEdited: null,
      localPaletteNameValue: '',
      paletteNameOverride: false,
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', (e) => this.handleKeyboardInput(e))
  }

  handleKeyboardInput(e) {
    const key = e.keyCode || e.charCode
    // Enter
    if (
      key === 13 &&
      this.state.paletteRefBeingEdited !== undefined &&
      this.state.paletteRefBeingEdited !== null
    ) {
      this.refs[this.state.paletteRefBeingEdited].blur()
    }
  }

  toggleActivePalette = (e, index, paletteType) => {
    if (
      this.state.paletteIndexBeingEdited !== index ||
      (this.state.paletteIndexBeingEdited === index &&
        this.state.paletteTypeBeingEdited !== paletteType)
    ) {
      e.preventDefault()
    }
    if (this.state.paletteIndexBeingEdited !== null) {
      if (
        (this.state.paletteIndexBeingEdited !== index ||
          this.state.paletteTypeBeingEdited !== paletteType) &&
        this.refs[this.state.paletteRefBeingEdited] !== undefined
      ) {
        this.refs[this.state.paletteRefBeingEdited].blur()
        this.handleBlur()
      }
    }
    if (
      this.state.paletteIndexBeingEdited === index &&
      this.state.paletteTypeBeingEdited === paletteType
    ) {
      return
    }
    this.props.setActivePalette(index, paletteType)
  }

  handleDoubleClick = (index, paletteType) => {
    const newPaletteRefBeingEdited = paletteType + index
    const newPaletteIndexBeingEdited = index
    const newPaletteTypeBeingEdited = paletteType
    this.setState(
      {
        paletteNameIsBeingEdited: true,
        paletteIndexBeingEdited: newPaletteIndexBeingEdited,
        paletteRefBeingEdited: newPaletteRefBeingEdited,
        paletteTypeBeingEdited: newPaletteTypeBeingEdited,
      },
      () => this.refs[paletteType + index].focus()
    )
  }

  getPaletteName = (palette, index, paletteType) => {
    if (
      this.state.paletteIndexBeingEdited === index &&
      this.state.paletteNameOverride === true &&
      this.state.paletteTypeBeingEdited === paletteType
    ) {
      return this.state.localPaletteNameValue
    }
    return palette.paletteName
  }

  setPaletteName = (value) => {
    let isValidName = true
    if (!value.replace(/\s/g, '').length) {
      isValidName = false
    }
    if (isValidName === true) {
      this.props.changePaletteName(value)
      this.setState({ paletteNameOverride: false })
    } else {
      this.setState({ paletteNameOverride: true })
    }
    this.setState({
      localPaletteNameValue: value,
    })
  }

  handleBlur = (value) => {
    this.setState({
      paletteNameIsBeingEdited: false,
      paletteNameOverride: false,
      paletteIndexBeingEdited: null,
      paletteRefBeingEdited: null,
    })
    this.setPaletteName(value.trim())
    this.props.handleInputFocus(false)
  }

  handleHover = (index) => {
    this.setState({
      currentHoverIndex: index,
    })
  }

  render() {
    const paletteList = (
      <PalettePanelList>
        {this.props.palettes.map((palette, index) => (
          <PalettePanelEntryWrapper
            key={'current' + index}
            onMouseOver={() => this.setState({ currentHoverIndex: index })}
            onMouseLeave={() => this.setState({ currentHoverIndex: null })}
          >
            <PalettePanelEntry
              key={'current' + index}
              ref={'current' + index}
              type="text"
              autoComplete="off"
              spellCheck="false"
              paletteIsSelected={
                this.props.activePaletteIndexCurrent === index ? true : false
              }
              onMouseDown={(e) => this.toggleActivePalette(e, index, 'current')}
              onDoubleClick={() => this.handleDoubleClick(index, 'current')}
              paletteNameIsBeingEdited={
                this.state.paletteIndexBeingEdited === index &&
                this.state.paletteNameIsBeingEdited
                  ? true
                  : false
              }
              onChange={(e) => this.setPaletteName(e.target.value)}
              onFocus={() => this.props.handleInputFocus(true)}
              onBlur={(e) => this.handleBlur(e.target.value)}
              value={this.getPaletteName(palette, index, 'current')}
            />
            <SwitchLegacyButton
              isVisible={this.state.currentHoverIndex === index}
              onClick={() => this.props.pushPaletteToLegacy(index)}
            >
              L
            </SwitchLegacyButton>
          </PalettePanelEntryWrapper>
        ))}
      </PalettePanelList>
    )

    const legacyPaletteList = (
      <PalettePanelList>
        {this.props.legacyPalettes.map((palette, index) => (
          <PalettePanelEntryWrapper key={'legacy' + index}>
            <PalettePanelEntry
              key={'legacy' + index}
              ref={'legacy' + index}
              type="text"
              autoComplete="off"
              spellCheck="false"
              paletteIsSelected={
                this.props.activePaletteIndexLegacy === index ? true : false
              }
              onMouseDown={(e) => this.toggleActivePalette(e, index, 'legacy')}
              onDoubleClick={() => this.handleDoubleClick(index, 'legacy')}
              paletteNameIsBeingEdited={
                this.state.paletteIndexBeingEdited === index &&
                this.state.paletteNameIsBeingEdited
                  ? true
                  : false
              }
              onChange={(e) => this.setPaletteName(e.target.value)}
              onFocus={() => this.props.handleInputFocus(true)}
              onBlur={(e) => this.handleBlur(e.target.value)}
              value={this.getPaletteName(palette, index, 'legacy')}
            />
            <SwitchLegacyButton
              isVisible
              onClick={() => this.props.pushPaletteToCurrent(index)}
            >
              L
            </SwitchLegacyButton>
          </PalettePanelEntryWrapper>
        ))}
      </PalettePanelList>
    )

    return (
      <Fragment>
        {this.props.mode === 'edit' && (
          <Heading>
            Current Palettes
            <AddRemoveButtonContainer>
              <AddRemoveButton onMouseDown={this.props.removePalette}>
                –
              </AddRemoveButton>
              <AddRemoveButton onMouseDown={this.props.addPalette}>
                +
              </AddRemoveButton>
            </AddRemoveButtonContainer>
          </Heading>
        )}
        <ScrollContainer>
          {this.props.mode === 'edit' && paletteList}
          {this.props.legacyPalettes.length !== 0 && (
            <Heading>Legacy Palettes</Heading>
          )}
          {legacyPaletteList}
        </ScrollContainer>
        <ThemeSwitcherWrapper>
          <Button onClick={this.props.handleThemeSwitch}>
            {this.props.darkTheme ? 'Light mode' : 'Dark mode'}
          </Button>
        </ThemeSwitcherWrapper>
      </Fragment>
    )
  }
}

LeftPanelContent.propTypes = {
  activePaletteIndexCurrent: PropTypes.number,
  activePaletteIndexLegacy: PropTypes.number,
  addPalette: PropTypes.func,
  changePaletteName: PropTypes.func,
  handleInputFocus: PropTypes.func,
  legacyPalettes: PropTypes.array,
  mode: PropTypes.string,
  palettes: PropTypes.array,
  pushPaletteToCurrent: PropTypes.func,
  pushPaletteToLegacy: PropTypes.func,
  removePalette: PropTypes.func,
  setActivePalette: PropTypes.func,
  handleThemeSwitch: PropTypes.func,
  darkTheme: PropTypes.bool,
}

export default LeftPanelContent
