import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'utils/media-queries'

const Div = styled.div`
  box-shadow: ${(props) => props.theme.buttonShadow};
  color: ${(props) => props.theme.buttonText};
  border: ${(props) => props.theme.buttonBorder};
  cursor: pointer;
  user-select: none;
  width: ${(props) => (props.small ? '96px' : '208px')};
  font-size: ${(props) => (props.plus ? '23px' : 'inherit')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  background: ${(props) => props.theme.buttonBackground};
  margin-right: 16px;
  transition: box-shadow 300ms;
  &:hover {
    background: ${(props) => props.theme.buttonHoverBackground};
    box-shadow: ${(props) => props.theme.buttonShadowHover};
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:active {
    background: ${(props) => props.theme.buttonActiveBackground};
    border: ${(props) => props.theme.buttonActiveBorder};
  }
  ${media.lg`
    width: ${(props) => (props.small ? '64px' : '208px')};
	`};
`

const Button = (props) => {
  return (
    <Div
      className={props.className}
      small={props.small}
      plus={props.plus}
      onClick={props.onClick}
    >
      {props.children}
    </Div>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  plus: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
}

export { Button }

const ToggleDiv = styled.div`
  box-shadow: ${(props) => props.theme.buttonShadow};
  color: ${(props) => props.theme.buttonText};
  border: ${(props) =>
    props.toggled ? props.theme.buttonToggledBorder : props.theme.buttonBorder};
  cursor: pointer;
  user-select: none;
  width: 56px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  background: ${(props) =>
    props.toggled
      ? props.theme.buttonToggledBackground
      : props.theme.buttonBackground};
  &:active {
    background: ${(props) =>
      props.toggled
        ? props.theme.buttonToggledBackground
        : props.theme.buttonBackground};
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const ToggleButton = (props) => {
  return (
    <ToggleDiv onMouseDown={props.toggle} toggled={props.toggled}>
      {props.children}
    </ToggleDiv>
  )
}

ToggleButton.propTypes = {
  children: PropTypes.any,
  toggle: PropTypes.func,
  toggled: PropTypes.bool,
}

export { ToggleButton }

const ShadowDiv = styled.div`
  color: ${(props) => props.theme.buttonText};
  cursor: pointer;
  user-select: none;
  width: ${(props) => (props.small ? '56px' : '208px')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  border: ${(props) => props.theme.shadowButtonBorder};
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0;
  }
  &:active {
    background: ${(props) => props.theme.shadowButtonActiveBackground};
  }
`

const ShadowButton = (props) => {
  return (
    <ShadowDiv small={props.small} onClick={props.onClick}>
      {props.children}
    </ShadowDiv>
  )
}

ShadowButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  small: PropTypes.bool,
}

export { ShadowButton }

const SplashDiv = styled.div`
  cursor: pointer;
  font-weight: ${(props) => (props.primary ? 'bold' : 'normal')};
  user-select: none;
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 4px;
  margin-right: 32px;
  background: ${(props) =>
    props.primary
      ? props.theme.splashButtonPrimaryBackground
      : props.theme.splashButtonSecondaryBackground};
  &:last-of-type {
    margin-right: 0;
  }
  &:active {
    background: ${(props) =>
      props.primary
        ? props.theme.splashButtonPrimaryActiveBackground
        : props.theme.splashButtonSecondaryActiveBackground};
  }
`

const SplashButton = (props) => {
  return (
    <SplashDiv primary={props.primary} onClick={props.onClick}>
      {props.children}
    </SplashDiv>
  )
}

SplashButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  shadow: PropTypes.bool,
  primary: PropTypes.bool,
}

export { SplashButton }
