import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ToggleButton } from 'components/button'

const LeftButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

const RightButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`

const Label = styled.div`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  color: ${(props) => props.theme.topPanelLabel};
  font-size: 12px;
  margin-bottom: 0px;
`

const SingleButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const SingleButtonAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  &:last-of-type {
    margin: 0;
  }
`

const ViewButtonAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
`

const ViewButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

const IconColor = styled.span`
  color: ${(props) => props.theme.icon};
`

function TopPanelContent(props) {
  return (
    <Fragment>
      <LeftButtonWrapper>
        <SingleButtonAndLabelWrapper>
          <SingleButtonWrapper>
            <Button plus small onClick={props.addSwatch}>
              <IconColor>+</IconColor>
            </Button>
          </SingleButtonWrapper>
          <Label>Add Swatch</Label>
        </SingleButtonAndLabelWrapper>
        <ViewButtonAndLabelWrapper>
          <ViewButtonWrapper>
            <ToggleButton toggle={props.toggleHue} toggled={props.hueIsVisible}>
              H
            </ToggleButton>
            <ToggleButton
              toggle={props.toggleSaturation}
              toggled={props.saturationIsVisible}
            >
              S
            </ToggleButton>
            <ToggleButton
              toggle={props.toggleValue}
              toggled={props.valueIsVisible}
            >
              V
            </ToggleButton>
          </ViewButtonWrapper>
          <Label>Toggle Handles</Label>
        </ViewButtonAndLabelWrapper>
        <SingleButtonAndLabelWrapper>
          <SingleButtonWrapper>
            <Button small onClick={props.straighten}>
              <svg
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clipRule="evenodd"
                  fill={props.darkTheme ? '#f0f0f0' : '#666'}
                  fillRule="evenodd"
                >
                  <path d="m3.25926 22.7778c1.12502 0 2.03704-.912 2.03704-2.0371 0-1.125-.91202-2.037-2.03704-2.037s-2.03704.912-2.03704 2.037c0 1.1251.91202 2.0371 2.03704 2.0371zm0 1.2222c1.80004 0 3.25926-1.4592 3.25926-3.2593 0-1.8-1.45922-3.2592-3.25926-3.2592s-3.25926 1.4592-3.25926 3.2592c0 1.8001 1.45922 3.2593 3.25926 3.2593z" />
                  <path d="m12 14.037c1.125 0 2.037-.912 2.037-2.037s-.912-2.03703-2.037-2.03703-2.03704.91203-2.03704 2.03703.91204 2.037 2.03704 2.037zm0 1.2223c1.8 0 3.2593-1.4593 3.2593-3.2593s-1.4593-3.25926-3.2593-3.25926-3.25926 1.45926-3.25926 3.25926 1.45926 3.2593 3.25926 3.2593z" />
                  <path d="m20.7407 5.2963c1.1251 0 2.0371-.91202 2.0371-2.03704s-.912-2.03704-2.0371-2.03704c-1.125 0-2.037.91202-2.037 2.03704s.912 2.03704 2.037 2.03704zm0 1.22222c1.8001 0 3.2593-1.45922 3.2593-3.25926s-1.4592-3.25926-3.2593-3.25926c-1.8 0-3.2592 1.45922-3.2592 3.25926s1.4592 3.25926 3.2592 3.25926z" />
                  <path d="m10.5955 14.2768-5.02066 5.0237-.86456-.864 5.02068-5.0236z" />
                  <path d="m19.2984 5.58259-5.0241 5.01771-.8636-.86489 5.0241-5.01774z" />
                </g>
              </svg>
            </Button>
          </SingleButtonWrapper>
          <Label>Interpolate</Label>
        </SingleButtonAndLabelWrapper>
      </LeftButtonWrapper>
      <RightButtonWrapper>
        <SingleButtonAndLabelWrapper>
          <SingleButtonWrapper>
            <Button small onClick={() => props.handleModeChange('map')}>
              <IconColor>
                <b>□ → □</b>
              </IconColor>
            </Button>
          </SingleButtonWrapper>
          <Label>Map Palettes</Label>
        </SingleButtonAndLabelWrapper>
        <SingleButtonAndLabelWrapper>
          <SingleButtonWrapper>
            <Button onClick={props.importPalettes}>Import Palettes</Button>
          </SingleButtonWrapper>
        </SingleButtonAndLabelWrapper>
        <SingleButtonAndLabelWrapper>
          <SingleButtonWrapper>
            <Button onClick={props.exportPalettes}>Export Palettes</Button>
          </SingleButtonWrapper>
        </SingleButtonAndLabelWrapper>
      </RightButtonWrapper>
    </Fragment>
  )
}

TopPanelContent.propTypes = {
  addSwatch: PropTypes.func,
  exportPalettes: PropTypes.func,
  handleModeChange: PropTypes.func,
  hueIsVisible: PropTypes.bool,
  importPalettes: PropTypes.func,
  saturationIsVisible: PropTypes.bool,
  straighten: PropTypes.func,
  toggleHue: PropTypes.func,
  toggleSaturation: PropTypes.func,
  toggleValue: PropTypes.func,
  valueIsVisible: PropTypes.bool,
  darkTheme: PropTypes.bool,
}

export default TopPanelContent
