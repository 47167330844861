import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convert from 'color-convert'
import debounce from 'lodash.debounce'

import EmptyLightImg from 'img/legacyexplainer-light.png'
import EmptyDarkImg from 'img/legacyexplainer-dark.png'

const EmptyImage = styled.div`
  background-image: ${(props) =>
    props.darkTheme
      ? 'url(' + EmptyDarkImg + ')'
      : 'url(' + EmptyLightImg + ')'};
  background-repeat: no-repeat;
  width: 384px;
  height: 320px;
  background-size: 100%;
`

const EmptyMessage = styled.div`
  padding: 32px;
  z-index: 5;
  color: ${(props) => props.theme.emptyMessageText};
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
`

const Heading = styled.div`
  width: 100%;
  height: 72px;
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
`

const LeftHeading = styled.div`
  flex: 1 1;
  min-width: 50%;
`

const RightHeading = styled.div`
  flex: 1 1;
  margin-left: 25px;
`

const Palette = styled.div`
  padding: 56px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow-y: auto;
`

const MappedColorWrapper = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 16px;
  display: flex;
  margin-right: 16px;
`

const ColorDivAndLabelWrapper = styled.div`
  height: 100px;
  flex: 1 1;
`

const ColorDiv = styled.div.attrs((props) => ({
  style: {
    background: props.color,
  },
}))`
  transition: transform 0.2s ease-out, border-radius 0.2s ease-out;
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.colorDivShadow};
`

const MappedColorDiv = styled.div.attrs((props) => ({
  style: {
    background: props.color,
  },
}))`
  transition: transform 0.2s ease-out, border-radius 0.2s ease-out;
  width: 100%;
  height: 50px;
  z-index: ${(props) => (props.isActiveSwatch ? '1' : '0')};
  box-shadow: ${(props) =>
    props.isVisible ? props.theme.colorDivShadow : 'none'};
  margin-bottom: 8px;
  border-radius: 4px;
`

const Label = styled.div`
  width: 100%;
`

const Arrow = styled.div`
  min-width: 50px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex: 0 0;
`

class MainContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sliderRange: 0,
      swatchWidth: 0,
      dialogCount: 0,
    }
  }

  _resizeHandler = debounce(() => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }, 100)

  componentDidMount() {
    window.addEventListener('resize', this._resizeHandler)
    this.setState({ windowHeight: window.innerHeight })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler)
  }

  hasPaletteSwatches = () => {
    if (
      this.props.activePalette === undefined ||
      this.props.activePalette.swatches.length === 0 ||
      this.props.currentPalettes.length === 0
    ) {
      return false
    } else {
      return true
    }
  }

  mapColor = (color) => {
    return color
  }

  getClosestColor = (color) => {
    let r1
    let g1
    let b1
    let newDiff
    let closestColor
    let closestColorName

    r1 = convert.hsv.rgb(color)[0]
    g1 = convert.hsv.rgb(color)[1]
    b1 = convert.hsv.rgb(color)[2]

    let minDiff

    this.props.currentPalettes.forEach((palette) => {
      palette.swatches.forEach((swatch) => {
        // Calculate Difference between old and new color
        newDiff = this.colorDifference(
          r1,
          g1,
          b1,
          convert.hsv.rgb(swatch.color)[0],
          convert.hsv.rgb(swatch.color)[1],
          convert.hsv.rgb(swatch.color)[2]
        )

        // If color with smaller difference is found take it as better approximation
        if (newDiff < minDiff || typeof minDiff === 'undefined') {
          minDiff = newDiff

          closestColor = swatch.color
          closestColorName = swatch.name
        }
      })
    })
    return { closestColor: closestColor, closestColorName: closestColorName }
  }

  colorDifference = (r1, g1, b1, r2, g2, b2) => {
    let sumOfSquares = 0

    sumOfSquares += Math.pow(r1 - r2, 2)
    sumOfSquares += Math.pow(g1 - g2, 2)
    sumOfSquares += Math.pow(b1 - b2, 2)
    return Math.sqrt(sumOfSquares)
  }

  getEmptyMessage = () => {
    if (this.props.activePalette === undefined) {
      if (this.props.paletteCount > 0) {
        return (
          <EmptyMessage isVisible={!this.hasPaletteSwatches()}>
            Please select a palette in the sidebar.
          </EmptyMessage>
        )
      } else {
        return (
          <EmptyMessage isVisible={!this.hasPaletteSwatches()}>
            <EmptyImage darkTheme={this.props.darkTheme} />
            Mark palettes as legacy in edit mode to map them to the most similar
            colors in your current palettes.
          </EmptyMessage>
        )
      }
    } else {
      if (this.props.currentPalettes.length === 0) {
        return (
          <EmptyMessage isVisible={true}>
            Add at least one palette to your current palettes.
          </EmptyMessage>
        )
      } else {
        return (
          <EmptyMessage isVisible={!this.hasPaletteSwatches()}>
            This palette is empty. Please add at least one swatch in edit mode.
          </EmptyMessage>
        )
      }
    }
  }

  render() {
    const mappedColors =
      this.props.activePalette &&
      this.props.currentPalettes.length > 0 &&
      this.props.activePalette.swatches.map((swatch, index) => (
        <MappedColorWrapper key={this.props.activePaletteIndex + '-' + index}>
          <ColorDivAndLabelWrapper>
            <ColorDiv color={'#' + convert.hsv.hex(swatch.color)} />
            <Label>
              {swatch.name}
              {swatch.name === '' ? '' : ' - '}#{convert.hsv.hex(swatch.color)}
            </Label>
          </ColorDivAndLabelWrapper>
          <Arrow>▶</Arrow>
          <ColorDivAndLabelWrapper>
            <MappedColorDiv
              color={
                '#' +
                convert.hsv.hex(this.getClosestColor(swatch.color).closestColor)
              }
              isVisible={this.props.currentPalettes.length !== 0}
            />
            <Label>
              {this.getClosestColor(swatch.color).closestColorName}
              {this.getClosestColor(swatch.color).closestColorName === ''
                ? ''
                : ' - '}
              #
              {convert.hsv.hex(this.getClosestColor(swatch.color).closestColor)}
            </Label>
          </ColorDivAndLabelWrapper>
        </MappedColorWrapper>
      ))

    return (
      <Fragment>
        {this.getEmptyMessage()}
        <Palette>
          <Heading isVisible={this.hasPaletteSwatches()}>
            <LeftHeading isVisible={this.hasPaletteSwatches()}>
              Legacy:{' '}
              {this.props.activePalette && this.props.activePalette.paletteName}
            </LeftHeading>
            <RightHeading isVisible={this.hasPaletteSwatches()}>
              Closest colors in current palettes
            </RightHeading>
          </Heading>
          {mappedColors}
        </Palette>
      </Fragment>
    )
  }
}

MainContent.propTypes = {
  activePalette: PropTypes.object,
  activePaletteIndex: PropTypes.number,
  currentPalettes: PropTypes.array,
  paletteCount: PropTypes.number,
  darkTheme: PropTypes.bool,
}

export default MainContent
