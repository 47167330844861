import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convert from 'color-convert'
import ContentEditable from 'react-contenteditable'

import { Button, ShadowButton, SplashButton } from 'components/button'

import SplashImg from 'img/palettte.jpg'
import InterpolateExplainerDarkImg from 'img/interpolateexplainer-dark.png'
import InterpolateExplainerLightImg from 'img/interpolateexplainer-light.png'
import importPlaceholder from 'input/import-placeholder'
import importDemo from 'input/import-demo'

const Div = styled.div`
  width: 700px;
  height: ${(props) => props.height + 'px'};
  color: white;
  font-size: 16px;
  background: ${(props) =>
    props.splash
      ? props.theme.dialogSplashBackground
      : props.theme.dialogBackground};
  z-index: 100;
  position: relative;
  box-shadow: 0px 20px 50px -10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 4px;
`

const Header = styled.div`
  width: 100%;
  color: ${(props) => props.theme.dialogText};
  padding: 24px 24px 16px 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
  user-select: none;
`

const Content = styled.div`
  padding: 16px 24px;
  flex-grow: 1;
  color: ${(props) => props.theme.dialogText};
`

const InterpolateExplainerImage = styled.div`
  background-image: ${(props) =>
    props.darkTheme
      ? 'url(' + InterpolateExplainerDarkImg + ')'
      : 'url(' + InterpolateExplainerLightImg + ')'};
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 100px;
  width: 300px;
  margin-bottom: 16px;
`

const SplashContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #004469;
  color: #2a88bb;
  padding-bottom: 24px;
`

const SplashImage = styled.div`
  background-image: url(${SplashImg});
  background-size: 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 480px;
  height: 412px;
  max-height: 319px;
`

const Credit = styled.div`
  display: flex;
  align-items: center;
  max-height: 20px;
`

const TwitterIcon = styled.svg`
  margin: 0 2px 0 20px;
`

const TwitterLink = styled.a`
  color: #2a88bb;
  &:visited {
    color: #2a88bb;
  }
  &:hover {
    color: #2a88bb;
  }
  &:active {
    color: #2a88bb;
  }
`

const MotivationLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`

const SplashFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  background: #004469;
  padding-bottom: 56px;
`

const Checkbox = styled.input`
  transform: scale(1.4) translateY(-2px);
`

const TextAreaWrapper = styled.div`
  position: relative;
  height: 186px;
  width: 100%;
`

const TextAreaOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isFocused ? 'none' : 'block')};
  background: ${(props) => props.theme.dialogInputOverlay};
  border-radius: 4px;
`

const TextArea = styled(ContentEditable)`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${(props) => props.theme.dialogInputBackground};
  border: ${(props) => props.theme.dialogInputBorder};
  color: ${(props) => props.theme.dialogInputText};
  resize: none;
  overflow: auto;
  font-family: monospace;
  font-size: 1em;
  padding: 16px;
  border-radius: 4px;
`

const ExportTextArea = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${(props) => props.theme.dialogInputBackground};
  border: ${(props) => props.theme.dialogInputBorder};
  color: ${(props) => props.theme.dialogInputText};
  resize: none;
  overflow: auto;
  font-family: monospace;
  font-size: 1em;
  padding: 16px;
  border-radius: 4px;
`

const Fieldset = styled.fieldset`
  border: none;
`

const Label = styled.label`
  padding: 0px 0px 0px 8px;
  user-select: none;
`

const RadioButtonGroupWrapper = styled.span`
  margin-right: 32px;
`

const ButtonAndResponseWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CopyFeedback = styled.div`
  color: ${(props) => props.theme.copyFeedback};
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  user-select: none;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  background: ${(props) => props.theme.dialogFooterBackground};
  border: ${(props) => props.theme.dialogFooterBorder};
`

class DialogFrame extends React.Component {
  constructor(props) {
    super(props)
    this.htmlElement = null
    this.state = {
      importTextAreaIsFocused: false,
      doNotAskAgain: false,
      exportTo: 'JSON',
      radioButtonJSON: 'checked',
      radioButtonCSS: '',
      radioButtonHex: '',
      exportIsCopied: false,
      importValue: importPlaceholder,
    }
  }

  componentDidMount() {
    document.addEventListener('paste', this.copyWithoutFormat)
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.copyWithoutFormat)
  }

  copyWithoutFormat = (e) => {
    // cancel paste
    e.preventDefault()

    // get text representation of clipboard
    var text = (e.originalEvent || e).clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand('insertHTML', false, text)
  }

  setImportValue = (e) => {
    this.setState({
      importValue: e.target.value,
    })
  }

  onKeyDown = (e) => {
    if (e.keyCode === 9) {
      // tab key
      e.preventDefault() // this will prevent us from tabbing out of the editor

      // now insert four non-breaking spaces for the tab key
      var editor = e.target
      var doc = editor.ownerDocument.defaultView
      var sel = doc.getSelection()
      var range = sel.getRangeAt(0)

      var tabNode = document.createTextNode('\u00a0\u00a0')
      range.insertNode(tabNode)

      range.setStartAfter(tabNode)
      range.setEndAfter(tabNode)
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }

  handleDoNotAskAgainCheckbox = (e) => {
    this.setState({
      doNotAskAgain: e.target.checked,
    })
    this.props.handleRemovePaletteMustBeConfirmedChange(!e.target.checked)
  }

  getExportValue = () => {
    const palettesHSV = JSON.parse(JSON.stringify(this.props.palettes))
    if (this.state.exportTo === 'JSON') {
      palettesHSV.forEach((palette, paletteIndex) =>
        palette.swatches.forEach((swatch, swatchIndex) => {
          const hex = convert.hsv.hex(swatch.color)
          palettesHSV[paletteIndex].swatches[swatchIndex].color = hex
        })
      )
      const palettesHex = palettesHSV
      const palettesHexString =
        '<pre>' + JSON.stringify(palettesHex, null, 2) + '</pre>'
      return { __html: palettesHexString }
    } else if (this.state.exportTo === 'CSS') {
      let cssString = ''
      palettesHSV.forEach((palette, paletteIndex) => {
        palette.swatches.forEach((swatch, swatchIndex) => {
          const hex = convert.hsv.hex(swatch.color)
          const colorName = palettesHSV[paletteIndex].swatches[swatchIndex].name
          cssString += `\xa0\xa0--${colorName.replace(/ /g, '-')}: #${hex};\n`
        })
      })
      const htmlString = '<pre>:root {\n' + cssString + '}</pre>'
      return { __html: htmlString }
    } else if (this.state.exportTo === 'Hex') {
      let hexString = ''
      let hexStrings = []
      palettesHSV.forEach((palette, paletteIndex) => {
        hexStrings[paletteIndex] = `${palette.paletteName}\n\n`
        palette.swatches.forEach((swatch) => {
          const hex = convert.hsv.hex(swatch.color)
          hexStrings[paletteIndex] += `#${hex}\n`
        })
        hexString += `${hexStrings[paletteIndex]}\n`
      })
      const htmlString = '<pre>' + hexString + '</pre>'
      return { __html: htmlString }
    }

    return 'error'
  }

  handleExportRadioButtons = (e) => {
    if (e.target.value === 'CSS') {
      this.setState({
        exportTo: e.target.value,
        radioButtonJSON: '',
        radioButtonCSS: 'checked',
        radioButtonHex: '',
        exportIsCopied: false,
      })
    } else if (e.target.value === 'Hex') {
      this.setState({
        exportTo: e.target.value,
        radioButtonJSON: '',
        radioButtonCSS: '',
        radioButtonHex: 'checked',
        exportIsCopied: false,
      })
    } else if (e.target.value === 'JSON') {
      this.setState({
        exportTo: e.target.value,
        radioButtonJSON: 'checked',
        radioButtonCSS: '',
        radioButtonHex: '',
        exportIsCopied: false,
      })
    }
  }

  copyToClipboard = () => {
    window.getSelection().selectAllChildren(this.refs.exportTextArea)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    this.setState({
      exportIsCopied: true,
    })
  }

  render() {
    if (this.props.dialog.type === 'info') {
      return (
        <Div height={350}>
          <Header>{this.props.dialog.title}</Header>
          <Content>
            <InterpolateExplainerImage darkTheme={this.props.darkTheme} />
            {this.props.dialog.text}
          </Content>
          <Footer>
            <Button onClick={this.props.closeDialog}>OK</Button>
          </Footer>
        </Div>
      )
    } else if (this.props.dialog.type === 'confirm') {
      return (
        <Div height={300}>
          <Header>{this.props.dialog.title}</Header>
          <Content>
            {this.props.dialog.text}
            <br />
            <br />
            <Checkbox
              id="doNotAskAgain"
              type="checkbox"
              checked={this.state.doNotAskAgain}
              onChange={this.handleDoNotAskAgainCheckbox}
            />
            <Label htmlFor="doNotAskAgain">&nbsp;&nbsp;Do not ask again</Label>
          </Content>

          <Footer>
            <Button onClick={this.props.closeDialog}>Cancel</Button>
            <Button
              onClick={() => {
                this.props.closeDialog()
                this.props.dialog.onConfirm()
              }}
            >
              {this.props.dialog.buttonText}
            </Button>
          </Footer>
        </Div>
      )
    } else if (this.props.dialog.type === 'splash') {
      return (
        <Div height={500} splash>
          <SplashContent>
            <SplashImage />
            <Credit>
              by Gabriel Schneider
              <TwitterIcon
                height="20"
                viewBox="0 0 24 20"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                // preserveAspectRatio
              >
                <path
                  d="m23.0823776 2.2202362c-.8583759.37653128-1.7730151.62610949-2.7266058.74729197.981001-.58571533 1.7297356-1.50612514 2.0817419-2.61552191-.9146392.54532117-1.9244932.93050835-3.0007091 1.14546299-.8684745-.92473775-2.106267-1.49746925-3.456586-1.49746925-2.6198499 0-4.7290022 2.12646404-4.7290022 4.73333006 0 .37508864.0317383.73575079.1096413 1.07910115-3.9341027-.19187226-7.41521378-2.07741398-9.75374716-4.94972735-.40826955.70834047-.64774922 1.51910898-.64774922 2.39191138 0 1.63884881.84394943 3.09159596 2.10193901 3.93266009-.76027581-.01442649-1.50612514-.23515172-2.13800522-.58283003v.05193535c0 2.29958187 1.64029145 4.20964865 3.79128052 4.64965645-.38518718.1053133-.80499792.155806-1.2406778.155806-.3029562 0-.60879771-.0173117-.89588478-.0807883.61312566 1.8740005 2.35295987 3.25173 4.42171796 3.2964521-1.60999584 1.2594322-3.6542289 2.0182654-5.86725186 2.0182654-.38807247 0-.76027581-.0173118-1.13247915-.0649192 2.09616842 1.3517617 4.58040931 2.1235787 7.25940776 2.1235787 8.70782694 0 13.46856734-7.213243 13.46856734-13.46568203 0-.20918405-.0072132-.41115485-.0173118-.61168301.9391643-.66650365 1.728293-1.49891189 2.3717143-2.45683056z"
                  fill="#2a88bb"
                  fillRule="evenodd"
                />
              </TwitterIcon>
              <TwitterLink target="blank" href="https://twitter.com/gabdorf">
                @gabdorf
              </TwitterLink>
            </Credit>
          </SplashContent>
          <SplashFooter>
            <SplashButton primary>
              <MotivationLink
                href="https://gabrielschneider.de/palettte-app"
                target="blank"
              >
                Read Tutorial
              </MotivationLink>
            </SplashButton>
          </SplashFooter>
        </Div>
      )
    } else if (this.props.dialog.type === 'import') {
      return (
        <Div height={500}>
          <Header>{this.props.dialog.title}</Header>
          <Content>
            {this.props.dialog.text}
            <br />
            <br />
            <TextAreaWrapper>
              <TextArea
                ref="contentEditable"
                html={this.state.importValue}
                disabled={false}
                onChange={this.setImportValue}
                onFocus={() => {
                  this.props.handleInputFocus(true)
                  this.setState({ importTextAreaIsFocused: true })
                }}
                onBlur={() => {
                  this.props.handleInputFocus(false)
                  this.setState({ importTextAreaIsFocused: false })
                }}
                onKeyDown={this.onKeyDown}
                spellCheck={false}
              />
              <TextAreaOverlay isFocused={this.state.importTextAreaIsFocused} />
            </TextAreaWrapper>
            <br />
            <ShadowButton
              onClick={() => this.setState({ importValue: importDemo })}
            >
              Load Demo Palettes
            </ShadowButton>
          </Content>
          <Footer>
            <Button onClick={this.props.closeDialog}>Cancel</Button>
            <Button
              onClick={() => {
                this.props.dialog.onConfirm(this.state.importValue)
              }}
            >
              {this.props.dialog.buttonText}
            </Button>
          </Footer>
        </Div>
      )
    } else if (this.props.dialog.type === 'export') {
      return (
        <Div height={500}>
          <Header>{this.props.dialog.title}</Header>
          <Content>
            <Fieldset>
              <RadioButtonGroupWrapper>
                <input
                  type="radio"
                  id="r1"
                  name="export"
                  value="JSON"
                  onChange={this.handleExportRadioButtons}
                  checked={this.state.radioButtonJSON}
                />
                <Label htmlFor="r1">JSON</Label>
              </RadioButtonGroupWrapper>
              <RadioButtonGroupWrapper>
                <input
                  type="radio"
                  id="r2"
                  name="export"
                  value="CSS"
                  onChange={this.handleExportRadioButtons}
                  checked={this.state.radioButtonCSS}
                />
                <Label htmlFor="r2">CSS</Label>
              </RadioButtonGroupWrapper>
              <RadioButtonGroupWrapper>
                <input
                  type="radio"
                  id="r3"
                  name="export"
                  value="Hex"
                  onChange={this.handleExportRadioButtons}
                  checked={this.state.radioButtonHex}
                />
                <Label htmlFor="r3">Plain Hex</Label>
              </RadioButtonGroupWrapper>
            </Fieldset>
            <TextAreaWrapper>
              <ExportTextArea
                ref="exportTextArea"
                disabled={true}
                spellCheck={false}
                dangerouslySetInnerHTML={this.getExportValue()}
              />
              <TextAreaOverlay isFocused={this.state.importTextAreaIsFocused} />
            </TextAreaWrapper>
            <br />
            <ButtonAndResponseWrapper>
              <ShadowButton onClick={this.copyToClipboard}>
                Copy to Clipboard
              </ShadowButton>
              <CopyFeedback isVisible={this.state.exportIsCopied}>
                ✓ Copied
              </CopyFeedback>
            </ButtonAndResponseWrapper>
          </Content>
          <Footer>
            <Button onClick={this.props.closeDialog}>Close</Button>
          </Footer>
        </Div>
      )
    } else {
      return (
        <Div>
          <Content>Error</Content>
        </Div>
      )
    }
  }
}

DialogFrame.propTypes = {
  closeDialog: PropTypes.func,
  dialog: PropTypes.object,
  handleInputFocus: PropTypes.func,
  handleRemovePaletteMustBeConfirmedChange: PropTypes.func,
  palettes: PropTypes.array,
  darkTheme: PropTypes.bool,
}

export default DialogFrame
