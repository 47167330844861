const black = '#000'
const grey900 = '#131313'
const grey850 = '#181818'
const grey800 = '#1C1C1C'
const grey750 = '#222'
const grey700 = '#2C2C2C'
const grey600 = '#333'
const grey500 = '#444'
const grey400 = '#666'
const grey300 = '#888'
const grey200 = '#aaa'
const grey150 = '#d8d8d8'
const grey125 = '#e7e7e7'
const grey100 = '#f0f0f0'
// const grey75 = '#f6f6f6'
const grey50 = '#fbfbfb'
const white = '#fff'
const blue300 = '#41bcff'
const blue400 = '#0087d1'
const blue450 = '#0071af'
const blue500 = '#006399'
const blue550 = '#00517d'
const blue600 = '#004469'
const green300 = '#95ff9d'
const green500 = '#00980D'

export const darkTheme = {
  text: white,
  panelBorder: grey750,
  topPanelBorder: grey750,
  topPanelBackground: grey900,
  topPanelLabel: grey400,
  sidePanelBackground: grey850,
  leftPanelPaletteText: white,
  leftPanelPaletteSelectedBackground: grey700,
  leftPanelPaletteNameBeingEditedBackground: grey700,
  leftPanelSwitchLegacyButtonText: grey300,
  leftPanelAddRemoveButtonText: grey150,
  leftPanelThemeSwitcherBackground: grey900,
  rightPanelInputText: white,
  rightPanelInputBackground: grey750,
  rightPanelInputBorder: '1px solid transparent',
  rightPanelContrastInfoText: grey400,
  rightPanelContrastInfoBackground: grey750,
  mainPanelBackground: grey800,
  dialogBackground: grey700,
  dialogSplashBackground: blue600,
  dialogText: grey100,
  dialogInputBackground: grey800,
  dialogInputBorder: 'none',
  dialogInputOverlay:
    'linear-gradient(180deg, rgba(30, 30, 30, 0) 90%, rgba(25, 25, 25, 1) 100%)',
  dialogInputText: grey300,
  dialogFooterBackground: grey600,
  dialogFooterBorder: grey750,
  dialogPassepartoutOpacity: '0.5',
  emptyMessageText: grey500,
  buttonText: white,
  buttonBorder: 'none',
  buttonToggledBackground: grey500,
  buttonToggledBorder: 'none',
  buttonBackground: grey750,
  buttonHoverBackground: grey700,
  buttonActiveBackground: grey800,
  buttonActiveBorder: 'none',
  buttonShadow: `0px 1px 3px -2px ${black}`,
  buttonShadowHover: `0px 2px 10px -4px ${black}`,
  shadowButtonBorder: `2px solid ${grey500}`,
  shadowButtonActiveBackground: grey600,
  splashButtonPrimaryBackground: blue400,
  splashButtonPrimaryActiveBackground: blue450,
  splashButtonSecondaryBackground: blue550,
  splashButtonSecondaryActiveBackground: blue500,
  heading: grey500,
  inputFocusBorder: grey500,
  inputSelectionBackground: grey200,
  tableBorder: grey700,
  mobileBlockerBackground: grey900,
  mobileBlockerText: grey100,
  icon: grey100,
  colorDivShadow: '0px 20px 50px -10px rgba(0, 0, 0, 0.5)',
  colorDivActiveShadow: '0px 10px 100px -10px rgba(0, 0, 0, 0.5)',
  copyFeedback: green300,
  feedbackFocusRing: blue500,
  feedbackShadow: `0px 3px 15px -3px ${black}`,
  feedbackShadowHover: `0px 4px 20px -5px ${black}`,
}

export const lightTheme = {
  text: grey900,
  panelBorder: grey125,
  topPanelBorder: grey125,
  topPanelBackground: grey100,
  leftPanelPaletteText: grey900,
  topPanelLabel: grey400,
  sidePanelBackground: grey50,
  leftPanelPaletteSelectedBackground: grey125,
  leftPanelPaletteNameBeingEditedBackground: white,
  leftPanelSwitchLegacyButtonText: grey300,
  leftPanelAddRemoveButtonText: grey400,
  leftPanelThemeSwitcherBackground: grey100,
  rightPanelInputText: grey900,
  rightPanelInputBackground: white,
  rightPanelInputBorder: `1px solid ${grey125}`,
  dialogInputOverlay:
    'linear-gradient(180deg, rgba(30, 30, 30, 0) 90%, rgba(25, 25, 25, 0.075) 100%)',
  rightPanelContrastInfoText: grey400,
  rightPanelContrastInfoBackground: grey100,
  mainPanelBackground: white,
  dialogBackground: grey50,
  dialogSplashBackground: blue600,
  dialogText: grey900,
  dialogInputBackground: white,
  dialogInputBorder: `1px solid ${grey125}`,
  dialogInputText: grey500,
  dialogFooterBackground: grey100,
  dialogFooterBorder: `1px solid ${grey125}`,
  dialogPassepartoutOpacity: '0.75',
  emptyMessageText: grey300,
  buttonText: grey500,
  buttonBorder: `1px solid ${grey125}`,
  buttonToggledBackground: `linear-gradient(0deg, ${grey100} 70%, ${grey125}  100%)`,
  buttonToggledBorder: `1px solid ${grey150}`,
  buttonBackground: white,
  buttonHoverBackground: white,
  buttonActiveBackground: `linear-gradient(0deg, ${grey100} 70%, ${grey125}  100%)`,
  buttonActiveBorder: `1px solid ${grey150}`,
  buttonShadow: `0px 1px 3px -2px ${grey300}`,
  buttonShadowHover: `0px 2px 5px -3px ${grey400}`,
  shadowButtonBorder: `1px solid ${grey150}`,
  shadowButtonActiveBackground: grey100,
  splashButtonPrimaryBackground: blue400,
  splashButtonPrimaryActiveBackground: blue450,
  splashButtonSecondaryBackground: blue550,
  splashButtonSecondaryActiveBackground: blue500,
  heading: grey300,
  inputFocusBorder: blue300,
  inputSelectionBackground: grey200,
  tableBorder: grey150,
  mobileBlockerBackground: white,
  mobileBlockerText: grey900,
  icon: grey400,
  colorDivShadow: `0px 9px 30px -5px ${grey200}`,
  colorDivActiveShadow: `0px 9px 30px -5px rgba(0, 0, 0, 0.3)`,
  copyFeedback: green500,
  feedbackFocusRing: blue500,
  feedbackShadow: `0px 3px 15px -3px ${grey200}`,
  feedbackShadowHover: `0px 4px 20px -5px ${grey400}`,
}
