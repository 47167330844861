import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import convert from 'color-convert'
import debounce from 'lodash.debounce'
import update from 'immutability-helper'

import Edges from 'components/edges'
import Nodes from 'components/nodes'

const EmptyMessage = styled.div`
  padding: 32px;
  z-index: 5;
  color: ${(props) => props.theme.emptyMessageText};
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
`

const Palette = styled.div`
  padding: 56px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`

const BoxShadow = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: ${(props) =>
    props.isVisible ? props.theme.colorDivShadow : 'none'};
  display: flex;
  justify-content: flex-stretch;
  align-items: center;
  border-radius: 4px;
`

const ColorDiv = styled.div.attrs((props) => ({
  style: {
    background: props.color,
  },
}))`
  transition: transform 0.2s ease-out, border-radius 0.2s ease-out;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.isActiveSwatch ? '1' : '0')};
  transform: ${(props) =>
    props.isActiveSwatch ? 'scaleX(1.1) scaleY(1.02)' : 'scale(1)'};
  position: relative;
  top: 0;
  border-radius: ${(props) =>
    props.isActiveSwatch
      ? '4px'
      : props.isFirst
      ? '4px 0px 0px 4px'
      : props.isLast
      ? '0px 4px 4px 0px'
      : '0px'};
`

class MainContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hueNodes: [],
      saturationNodes: [],
      valueNodes: [],
      sliderRange: 0,
      swatchWidth: 0,
      nodeSize: 16,
      dialogCount: 0,
      palettesHeight: 0,
      palettesWidth: 0,
    }
  }

  _resizeHandler = debounce(() => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
    this.setDimensionsAndPositions()
  }, 100)

  componentDidMount() {
    window.addEventListener('resize', this._resizeHandler)
    this.setState({ windowHeight: window.innerHeight })
    this.setDimensionsAndPositions()
    this.props.straighten(this.straighten)
  }

  componentDidUpdate(prevProps) {
    // If selected palette changes, a swatch was added or deleted or a palette was added or deleted generate new node list
    if (prevProps.palettesCount !== this.props.palettesCount) {
      this.setDimensionsAndPositions()
    } else if (prevProps.activePaletteIndex !== this.props.activePaletteIndex) {
      this.setDimensionsAndPositions()
    } else if (
      this.props.activePalette &&
      prevProps.activePalette &&
      prevProps.activePalette.swatches.length !==
        this.props.activePalette.swatches.length
    ) {
      this.setDimensionsAndPositions()
    } else if (
      prevProps.activePaletteIndexLegacy !== this.props.activePaletteIndexLegacy
    ) {
      this.setDimensionsAndPositions()
    }

    // If only colors have changed, simply update the node positions
    else if (prevProps.activePalette !== this.props.activePalette) {
      this.updatePositions()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeHandler)
  }

  isFirstColorDiv = (index) => {
    const isFirst = index === 0 ? true : false
    return isFirst
  }

  isLastColorDiv = (index) => {
    const isLast =
      this.props.activePalette.swatches.length - 1 === index ? true : false
    return isLast
  }

  setDimensionsAndPositions = () => {
    if (this.props.activePalette) {
      const ref = this.refs.boxShadow
      const width = ref.clientWidth / this.props.activePalette.swatches.length
      const height = ref.clientHeight - this.state.nodeSize
      this.setState(
        {
          sliderRange: height,
          swatchWidth: width,
          palettesHeight: ref.clientHeight,
          palettesWidth: ref.clientWidth,
        },
        this.setPositions
      )
    } else {
      this.setState({
        hueNodes: [],
        saturationNodes: [],
        valueNodes: [],
      })
    }
  }

  convertHueToPosition = (value) => {
    return this.state.sliderRange - (value * this.state.sliderRange) / 360
  }

  convertSaturationToPosition = (value) => {
    return this.state.sliderRange - (value * this.state.sliderRange) / 100
  }

  convertValueToPosition = (value) => {
    return this.state.sliderRange - (value * this.state.sliderRange) / 100
  }

  convertPositionToHue = (position) => {
    return 360 - (position / this.state.sliderRange) * 360
  }

  convertPositionToSaturation = (position) => {
    return 100 - (position / this.state.sliderRange) * 100
  }

  convertPositionToValue = (position) => {
    return 100 - (position / this.state.sliderRange) * 100
  }

  setPositions = () => {
    const swatchWidth = this.state.swatchWidth
    const hueNodes = []
    const saturationNodes = []
    const valueNodes = []
    this.props.activePalette.swatches.forEach((swatch, index) => {
      // const color = convert.hsv.hsl(this.props.activePalette.swatches[index].color)
      const color = this.props.activePalette.swatches[index].color
      const x = index * swatchWidth + swatchWidth / 2
      const hueY = this.convertHueToPosition(color[0])
      const saturationY = this.convertSaturationToPosition(color[1])
      const valueY = this.convertValueToPosition(color[2])
      hueNodes.push({ coordinates: { x: x, y: hueY }, isSelected: false })
      saturationNodes.push({
        coordinates: { x: x, y: saturationY },
        isSelected: false,
      })
      valueNodes.push({ coordinates: { x: x, y: valueY }, isSelected: false })
    })
    this.setState({
      hueNodes: hueNodes,
      saturationNodes: saturationNodes,
      valueNodes: valueNodes,
    })
  }

  updatePositions = () => {
    let newHueNodes = [...this.state.hueNodes]
    this.props.activePalette.swatches.forEach((swatch, index) => {
      let newY = this.convertHueToPosition(
        this.props.activePalette.swatches[index].color[0]
      )
      newHueNodes = update(newHueNodes, {
        [index]: { coordinates: { y: { $set: newY } } },
      })
    })
    let newSaturationNodes = [...this.state.saturationNodes]
    this.props.activePalette.swatches.forEach((swatch, index) => {
      let newY = this.convertSaturationToPosition(
        this.props.activePalette.swatches[index].color[1]
      )
      newSaturationNodes = update(newSaturationNodes, {
        [index]: { coordinates: { y: { $set: newY } } },
      })
    })
    let newValueNodes = [...this.state.valueNodes]
    this.props.activePalette.swatches.forEach((swatch, index) => {
      let newY = this.convertValueToPosition(
        this.props.activePalette.swatches[index].color[2]
      )
      newValueNodes = update(newValueNodes, {
        [index]: { coordinates: { y: { $set: newY } } },
      })
    })

    this.setState({
      hueNodes: newHueNodes,
      saturationNodes: newSaturationNodes,
      valueNodes: newValueNodes,
    })
  }

  onControlledHueDrag = (e, position, index) => {
    this.selectNode(index, 0, e)
    const newHue = this.convertPositionToHue(position.y)
    this.props.changeHSV(newHue, 0)
  }

  onControlledSaturationDrag = (e, position) => {
    const newSaturation = this.convertPositionToSaturation(position.y)
    this.props.changeHSV(newSaturation, 1)
  }

  onControlledValueDrag = (e, position) => {
    const newValue = this.convertPositionToValue(position.y)
    this.props.changeHSV(newValue, 2)
  }

  hasPaletteSwatches = () => {
    if (
      this.props.activePalette === undefined ||
      this.props.activePalette === null ||
      this.props.activePalette.swatches.length === 0
    ) {
      return false
    } else {
      return true
    }
  }

  selectNode = (selectedNodeIndex, colorChannel, e) => {
    let newHueNodes = [...this.state.hueNodes]
    let newSaturationNodes = [...this.state.saturationNodes]
    let newValueNodes = [...this.state.valueNodes]
    // Deselect all nodes if shift key is not pressed
    if (e.shiftKey !== true) {
      for (let index = 0; index < newHueNodes.length; index++) {
        newHueNodes[index].isSelected = false
        newSaturationNodes[index].isSelected = false
        newValueNodes[index].isSelected = false
      }
    }
    // Only select newly selected node
    switch (colorChannel) {
      case 0:
        newHueNodes = update(newHueNodes, {
          [selectedNodeIndex]: { isSelected: { $set: true } },
        })
        break
      case 1:
        newSaturationNodes = update(newSaturationNodes, {
          [selectedNodeIndex]: { isSelected: { $set: true } },
        })
        break
      case 2:
        newValueNodes = update(newValueNodes, {
          [selectedNodeIndex]: { isSelected: { $set: true } },
        })
        break
      default:
        break
    }
    this.setState({
      hueNodes: newHueNodes,
      saturationNodes: newSaturationNodes,
      valueNodes: newValueNodes,
    })
  }

  dialogCount = 0

  straighten = () => {
    const newHueNodes = this.straightenSingleChannel([...this.state.hueNodes])
    const newSaturationNodes = this.straightenSingleChannel([
      ...this.state.saturationNodes,
    ])
    const newValueNodes = this.straightenSingleChannel([
      ...this.state.valueNodes,
    ])
    this.setState({
      hueNodes: newHueNodes,
      saturationNodes: newSaturationNodes,
      valueNodes: newValueNodes,
    })
    const newHueNodeValues = newHueNodes.map((hueNode) =>
      this.convertPositionToHue(hueNode.coordinates.y)
    )
    const newSaturationNodeValues = newSaturationNodes.map((saturationNode) =>
      this.convertPositionToSaturation(saturationNode.coordinates.y)
    )
    const newValueNodeValues = newValueNodes.map((valueNode) =>
      this.convertPositionToValue(valueNode.coordinates.y)
    )
    this.props.changeHSV([
      newHueNodeValues,
      newSaturationNodeValues,
      newValueNodeValues,
    ])
    if (this.dialogCount === 3) {
      this.props.createDialog(
        'info',
        'Select two or more handles',
        'You have to select two or more handles to interpolate all handles in between.'
      )
    }
    this.dialogCount = 0
  }

  straightenSingleChannel = (nodes) => {
    const selectedNodes = nodes.reduce((outArray, node, index) => {
      if (nodes[index].isSelected === true) {
        outArray.push(index)
      }
      return outArray
    }, [])
    const firstSelectedNodeIndex = selectedNodes[0]
    const lastSelectedNodeIndex = selectedNodes[selectedNodes.length - 1]
    if (
      firstSelectedNodeIndex !== undefined &&
      lastSelectedNodeIndex !== undefined &&
      firstSelectedNodeIndex !== lastSelectedNodeIndex
    ) {
      const firstSelectedNodeY = nodes[firstSelectedNodeIndex].coordinates.y
      const lastSelectedNodeY = nodes[lastSelectedNodeIndex].coordinates.y
      const stepCount = lastSelectedNodeIndex - firstSelectedNodeIndex
      const yDiff = lastSelectedNodeY - firstSelectedNodeY
      const yStep = yDiff / stepCount
      for (let i = 0; i < stepCount - 1; i++) {
        const oldY = nodes[firstSelectedNodeIndex].coordinates.y
        const newY = oldY + yStep * (i + 1)
        nodes = update(nodes, {
          [firstSelectedNodeIndex + 1 + i]: {
            coordinates: { y: { $set: newY } },
          },
        })
      }
    } else {
      this.dialogCount++
    }
    return nodes
  }

  render() {
    const swatches =
      this.props.activePalette &&
      this.props.activePalette.swatches.map((swatch, index) => (
        <ColorDiv
          onMouseDown={() => this.props.setActiveSwatch(index)}
          key={this.props.activePaletteIndex + '-' + index}
          color={'#' + convert.hsv.hex(swatch.color)}
          isActiveSwatch={this.props.activeSwatchIndex === index ? true : false}
          isFirst={this.isFirstColorDiv(index)}
          isLast={this.isLastColorDiv(index)}
        />
      ))

    return (
      <Fragment>
        <EmptyMessage isVisible={!this.hasPaletteSwatches()}>
          {this.props.activePalette === null
            ? 'Create a new palette by clicking + in the left sidebar.'
            : "This palette is empty. Click on the 'Add Swatch' button or hit the space bar."}
        </EmptyMessage>
        <Palette>
          <BoxShadow ref="boxShadow" isVisible={this.hasPaletteSwatches()}>
            {swatches}
          </BoxShadow>
        </Palette>
        {this.props.activePalette && this.props.hueIsVisible && (
          <Edges
            nodes={this.state.hueNodes}
            nodeSize={this.state.nodeSize}
            viewBoxWidth={this.state.palettesWidth}
            viewBoxHeight={this.state.palettesHeight}
          />
        )}
        {this.props.activePalette && this.props.saturationIsVisible && (
          <Edges
            nodes={this.state.saturationNodes}
            nodeSize={this.state.nodeSize}
            viewBoxWidth={this.state.palettesWidth}
            viewBoxHeight={this.state.palettesHeight}
          />
        )}
        {this.props.activePalette && this.props.valueIsVisible && (
          <Edges
            nodes={this.state.valueNodes}
            nodeSize={this.state.nodeSize}
            viewBoxWidth={this.state.palettesWidth}
            viewBoxHeight={this.state.palettesHeight}
          />
        )}
        {this.props.activePalette && this.props.hueIsVisible && (
          <Nodes
            colorChannel={0}
            setActiveSwatch={this.props.setActiveSwatch}
            nodes={this.state.hueNodes}
            nodeSize={this.state.nodeSize}
            onDrag={this.onControlledHueDrag}
            activePalette={this.props.activePalette}
            activeSwatchIndex={this.props.activeSwatchIndex}
            activePaletteIndex={this.props.activePaletteIndex}
            selectedNodes={this.state.selectedHueNodes}
            selectNode={this.selectNode}
            swatchWidth={this.state.swatchWidth}
            darkTheme={this.props.darkTheme}
          />
        )}
        {this.props.activePalette && this.props.saturationIsVisible && (
          <Nodes
            colorChannel={1}
            setActiveSwatch={this.props.setActiveSwatch}
            nodes={this.state.saturationNodes}
            nodeSize={this.state.nodeSize}
            onDrag={this.onControlledSaturationDrag}
            activePalette={this.props.activePalette}
            activeSwatchIndex={this.props.activeSwatchIndex}
            activePaletteIndex={this.props.activePaletteIndex}
            selectedNodes={this.state.selectedSaturationNodes}
            selectNode={this.selectNode}
            swatchWidth={this.state.swatchWidth}
            darkTheme={this.props.darkTheme}
          />
        )}
        {this.props.activePalette && this.props.valueIsVisible && (
          <Nodes
            colorChannel={2}
            setActiveSwatch={this.props.setActiveSwatch}
            nodes={this.state.valueNodes}
            nodeSize={this.state.nodeSize}
            onDrag={this.onControlledValueDrag}
            activePalette={this.props.activePalette}
            activeSwatchIndex={this.props.activeSwatchIndex}
            activePaletteIndex={this.props.activePaletteIndex}
            selectedNodes={this.state.selectedValueNodes}
            selectNode={this.selectNode}
            swatchWidth={this.state.swatchWidth}
            darkTheme={this.props.darkTheme}
          />
        )}
      </Fragment>
    )
  }
}

MainContent.propTypes = {
  activePalette: PropTypes.object,
  activePaletteIndex: PropTypes.number,
  activePaletteIndexLegacy: PropTypes.number,
  activeSwatchIndex: PropTypes.number,
  changeHSV: PropTypes.func,
  createDialog: PropTypes.func,
  hueIsVisible: PropTypes.bool,
  palettesCount: PropTypes.number,
  saturationIsVisible: PropTypes.bool,
  setActiveSwatch: PropTypes.func,
  straighten: PropTypes.func,
  valueIsVisible: PropTypes.bool,
  darkTheme: PropTypes.bool,
}

export default MainContent
