import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Div = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  padding: 56px;
  z-index: 2;
`

const Svg = styled.svg`
  height: 100%;
  width: 100%;
  z-index: 2;
`

class Edges extends React.Component {
  render() {
    let previousX
    let previousY
    if (this.props.nodes.length > 1) {
      const edges = this.props.nodes.map((node, index) => {
        if (index === 0) {
          previousX = this.props.nodes[index].coordinates.x
          previousY =
            this.props.nodes[index].coordinates.y + this.props.nodeSize / 2
        } else {
          const newEdge = {
            x1: previousX,
            y1: previousY,
            x2: this.props.nodes[index].coordinates.x,
            y2: this.props.nodes[index].coordinates.y + this.props.nodeSize / 2,
          }
          previousX = this.props.nodes[index].coordinates.x
          previousY =
            this.props.nodes[index].coordinates.y + this.props.nodeSize / 2
          return newEdge
        }
        return null
      })
      edges.shift()

      return (
        <Div>
          <Svg
            width={this.props.viewBoxWidth}
            height={this.props.viewBoxHeight}
            viewBox={
              '0 0 ' + this.props.viewBoxWidth + ' ' + this.props.viewBoxHeight
            }
          >
            {edges.map((edge, index) => (
              <line
                key={index}
                x1={edge.x1}
                y1={edge.y1}
                x2={edge.x2}
                y2={edge.y2}
                style={{
                  stroke: '#000000',
                  strokeWidth: 2,
                }}
              />
            ))}
          </Svg>
        </Div>
      )
    }
    return null
  }
}

Edges.propTypes = {
  nodeSize: PropTypes.number,
  nodes: PropTypes.array,
  viewBoxHeight: PropTypes.number,
  viewBoxWidth: PropTypes.number,
}

export default Edges
