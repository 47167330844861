const importDemo = `<pre>
[
  {
    "paletteName": "Blues",
    "swatches": [
      {
        "name": "blue 900",
        "color": "06222F"
      },
      {
        "name": "blue 800",
        "color": "042D41"
      },
      {
        "name": "blue 700",
        "color": "023D5A"
      },
      {
        "name": "blue 600",
        "color": "02537A"
      },
      {
        "name": "blue 500",
        "color": "0673A9"
      },
      {
        "name": "blue 400",
        "color": "1E99D6"
      },
      {
        "name": "blue 300",
        "color": "4DB6EA"
      },
      {
        "name": "blue 200",
        "color": "8CD3F6"
      },
      {
        "name": "blue 100",
        "color": "B9E6FC"
      },
      {
        "name": "blue 50",
        "color": "E3F6FF"
      },
      {
        "name": "blue 25",
        "color": "F1FAFF"
      }
    ]
  },
  {
    "paletteName": "Greys",
    "swatches": [
      {
        "name": "grey 900",
        "color": "1B1B1B"
      },
      {
        "name": "grey 800",
        "color": "2B2B2B"
      },
      {
        "name": "grey 700",
        "color": "3D3D3D"
      },
      {
        "name": "grey 600",
        "color": "666666"
      },
      {
        "name": "grey 500",
        "color": "949494"
      },
      {
        "name": "grey 400",
        "color": "C4C4C4"
      },
      {
        "name": "grey 300",
        "color": "E0E0E0"
      },
      {
        "name": "grey 200",
        "color": "EDEDED"
      },
      {
        "name": "grey 100",
        "color": "F8F8F8"
      },
      {
        "name": "white",
        "color": "FFFFFF"
      }
    ]
  },
  {
    "paletteName": "Greens",
    "swatches": [
      {
        "name": "green 700",
        "color": "0E7500"
      },
      {
        "name": "green 600",
        "color": "23AA11"
      },
      {
        "name": "green 500",
        "color": "45CB32"
      },
      {
        "name": "green 400",
        "color": "6DE05D"
      },
      {
        "name": "green 300",
        "color": "9BF18F"
      },
      {
        "name": "green 200",
        "color": "C6F9BF"
      },
      {
        "name": "green 100",
        "color": "F1FFEF"
      }
    ]
  },
  {
    "paletteName": "Reds",
    "swatches": [
      {
        "name": "red 700",
        "color": "B51C1C"
      },
      {
        "name": "red 600",
        "color": "E63535"
      },
      {
        "name": "red 500",
        "color": "F55757"
      },
      {
        "name": "red 400",
        "color": "FC8181"
      },
      {
        "name": "red 300",
        "color": "FFBABA"
      },
      {
        "name": "red 200",
        "color": "FFE7E7"
      },
      {
        "name": "red 100",
        "color": "FFF7F7"
      }
    ]
  },
  {
    "paletteName": "Rainbow",
    "swatches": [
      {
        "name": "",
        "color": "CB4C4C"
      },
      {
        "name": "",
        "color": "DB5348"
      },
      {
        "name": "",
        "color": "E75B42"
      },
      {
        "name": "",
        "color": "EF6439"
      },
      {
        "name": "",
        "color": "F76C2D"
      },
      {
        "name": "",
        "color": "FB7019"
      },
      {
        "name": "",
        "color": "FD7A0B"
      },
      {
        "name": "",
        "color": "FF8507"
      },
      {
        "name": "",
        "color": "FF9709"
      },
      {
        "name": "",
        "color": "FFA616"
      },
      {
        "name": "",
        "color": "FFB725"
      },
      {
        "name": "",
        "color": "F9CE44"
      },
      {
        "name": "",
        "color": "ECE463"
      },
      {
        "name": "",
        "color": "C3E36F"
      },
      {
        "name": "",
        "color": "A0DD71"
      },
      {
        "name": "",
        "color": "7DD06C"
      },
      {
        "name": "",
        "color": "69C675"
      },
      {
        "name": "",
        "color": "61BE8A"
      },
      {
        "name": "",
        "color": "5DC19D"
      },
      {
        "name": "",
        "color": "5AC6B1"
      },
      {
        "name": "",
        "color": "58CBC1"
      },
      {
        "name": "",
        "color": "54CDD0"
      },
      {
        "name": "",
        "color": "51C3D5"
      },
      {
        "name": "",
        "color": "4FAFDC"
      },
      {
        "name": "",
        "color": "4DA8E1"
      },
      {
        "name": "",
        "color": "4D9EE6"
      },
      {
        "name": "",
        "color": "5B94E9"
      },
      {
        "name": "",
        "color": "748DEC"
      },
      {
        "name": "",
        "color": "8B86F1"
      },
      {
        "name": "",
        "color": "A288F2"
      },
      {
        "name": "",
        "color": "B487EE"
      },
      {
        "name": "",
        "color": "C284E8"
      },
      {
        "name": "",
        "color": "CC7CE2"
      },
      {
        "name": "",
        "color": "D272D8"
      },
      {
        "name": "",
        "color": "CF69C8"
      },
      {
        "name": "",
        "color": "C75FAF"
      },
      {
        "name": "",
        "color": "C55898"
      },
      {
        "name": "",
        "color": "C65381"
      },
      {
        "name": "",
        "color": "C95063"
      }
    ]
  }
]</pre>`

export default importDemo
