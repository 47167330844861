import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const HeadingWrapper = styled.div`
  padding: 24px 16px 0 16px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.panelBorder};
  margin-top: 24px;
  &:first-of-type {
    border: none;
    margin-top: 0;
  }
`

const StyledHeading = styled.div`
  color: ${(props) => props.theme.heading};
  text-transform: uppercase;
  font-weight: bold;
  height: 32px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  user-select: none;
  /* margin-top: -6px; */
`

function PaletteHeading(props) {
  return (
    <HeadingWrapper>
      <StyledHeading>{props.children}</StyledHeading>
    </HeadingWrapper>
  )
}

PaletteHeading.propTypes = {
  children: PropTypes.any,
}

export default PaletteHeading
