import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Draggable from 'react-draggable'

const Div = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  padding: 56px;
  z-index: 2;
`

const NodeLabel = styled.div.attrs((props) => ({
  style: {
    top: props.y + 'px',
    left: props.x + 'px',
  },
}))`
  position: absolute;
  color: ${(props) => (props.isLight ? 'black' : 'white')};
  text-align: right;
  transform: translate(25px, 51px);
  font-weight: bold;
  font-size: 14px;
`

const ClickableDiv = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 56px;
  z-index: 2;
`

const DraggablePoint = styled.div`
  pointer-events: all;
  width: ${(props) => props.nodeSize + 'px'};
  height: ${(props) => props.nodeSize + 'px'};
  border-radius: ${(props) => props.nodeSize + 'px'};
  z-index: ${(props) => (props.isActiveSwatch ? '3' : '0')};
  background: ${(props) => (props.isSelected ? 'black' : 'white')};
  border: ${(props) =>
    props.isSelected ? '2px solid white' : '2px solid black'};
  position: absolute;
`

class Node extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.nodes !== nextProps.nodes) {
      return true
    }
    return false
  }

  checkIfLight = (color) => {
    if (this.props.swatchWidth < 50) {
      if (this.props.darkTheme) {
        return false
      } else {
        return true
      }
    }
    const isLight = color[2] > 80 ? true : false

    return isLight
  }

  getCorrectedPosition = (index) => {
    if (this.props.nodes[index].coordinates !== undefined) {
      const correctedPosition = {
        x: this.props.nodes[index].coordinates.x - this.props.nodeSize / 2,
        y: this.props.nodes[index].coordinates.y,
      }
      return correctedPosition
    }
  }

  getColorChannel = () => {
    switch (this.props.colorChannel) {
      case 0:
        return 'H'
      case 1:
        return 'S'
      case 2:
        return 'V'
      default:
        return
    }
  }

  render() {
    const nodes = this.props.nodes.map((node, index) => (
      <ClickableDiv
        onMouseDown={() => this.props.setActiveSwatch(index, true)}
        key={this.props.activePaletteIndex + '-' + index}
      >
        <Draggable
          key={this.props.activePaletteIndex + '-' + index}
          position={this.getCorrectedPosition(index)}
          onDrag={(e, position) => this.props.onDrag(e, position, index)}
          onMouseDown={(e) =>
            this.props.selectNode(index, this.props.colorChannel, e)
          }
          axis="y"
          bounds="parent"
        >
          <DraggablePoint
            isLight={this.checkIfLight(
              this.props.activePalette.swatches[index].color
            )}
            isActiveSwatch={
              this.props.activeSwatchIndex === index ? true : false
            }
            isSelected={node.isSelected}
            nodeSize={this.props.nodeSize}
          />
        </Draggable>
      </ClickableDiv>
    ))
    return (
      <Div>
        {this.props.nodes.length !== 0 && (
          <NodeLabel
            darkTheme={this.props.darkTheme}
            isLight={
              this.props.activePalette.swatches[0] &&
              this.checkIfLight(this.props.activePalette.swatches[0].color)
            }
            x={this.props.nodes[0] && this.props.nodes[0].coordinates.x}
            y={this.props.nodes[0] && this.props.nodes[0].coordinates.y}
          >
            {this.getColorChannel()}
          </NodeLabel>
        )}
        {nodes}
      </Div>
    )
  }
}

Node.propTypes = {
  activePalette: PropTypes.object,
  activePaletteIndex: PropTypes.number,
  activeSwatchIndex: PropTypes.number,
  colorChannel: PropTypes.number,
  nodeSize: PropTypes.number,
  nodes: PropTypes.array,
  onDrag: PropTypes.func,
  selectNode: PropTypes.func,
  setActiveSwatch: PropTypes.func,
  swatchWidth: PropTypes.number,
  darkTheme: PropTypes.bool,
}

export default Node
