import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from 'components/button'

const LeftButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

const SingleButtonAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  &:last-of-type {
    margin: 0;
  }
`

function TopPanelContent(props) {
  return (
    <Fragment>
      <LeftButtonWrapper>
        <SingleButtonAndLabelWrapper>
          <Button onClick={() => props.handleModeChange('edit')}>
            ◀&nbsp;&nbsp;Back to edit mode
          </Button>
        </SingleButtonAndLabelWrapper>
      </LeftButtonWrapper>
    </Fragment>
  )
}

TopPanelContent.propTypes = {
  handleModeChange: PropTypes.func,
}

export default TopPanelContent
